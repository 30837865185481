<template>
  <div class="atmo-album-songs">
    <div
      class="atmo-album-songs__sidebar"
      @click="$emit('on-album-click', album)"
    >
      <img
        class="atmo-album-songs__album-image"
        :src="album.image.url"
        :alt="album.name"
      >
      <div class="atmo-album-songs__name-and-date">
        <span class="atmo-album-songs__name">
          {{ album.name }}
        </span>
        <span class="atmo-album-songs__date">
          {{ album.release_date }}
        </span>
      </div>
      <div class="atmo-album-songs__artist-name">
        {{ album.artist_profile.user.name }}
      </div>
    </div>
    <div v-if="album.songs == null">
      Loading...
    </div>
    <atmo-library-songs
      v-else
      :songs="album.songs" 
      :component-location="componentLocation"/>
  </div>
</template>

<script>
  import AtmoLibrarySongs from '@/components/atmo-library/library-songs'

  export default {
    components: { 
      // AtmoSongsTable 
      AtmoLibrarySongs
    },
    props: { 
      album: {
        type: Object, 
        required: true 
      },
     componentLocation: {
        type: String,
        default: null
      }, 
    },

    data() {
      return {
        songs: null,
      }
    },
    created() {},
    methods: {
    },
  }
</script>

<style lang="scss">
  @import '@/assets/stylesheets/_variables.scss';

  .atmo-album-songs {
    display: flex;

    & + & {
      margin-top: 2rem;
    }

    &__sidebar {
      display: flex;
      flex-direction: column;
      // margin-top: 25px;
      margin-left: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }

    &__album-image {
      height: 15rem;
      width: 15rem;
      box-shadow: 0px 2px 8px 0px rgba(0,0,0,.7);
      margin-top: 2.2rem;
    }

    &__name-and-date {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: space-between;
    }

    &__name {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 600;
      max-width: 12rem;
    }

    &__date {
      font-size: .8rem;
      background-color: $atmo-purple--medium-dark;
      border-radius: 12px;
      padding: 4px 10px 4px 10px;
      margin: 0 5px;
      box-shadow: 1px 1px 1px 0px rgba(black, 0.3);
    }

    &__artist-name {
      font-size: .8rem;
      color: $atmo-blue--medium;
      margin-top: 4px;
      font-weight: 500;
    }
  }
</style>
