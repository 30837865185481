import Vue from 'vue';
import HowlerManager from "@/helpers/services/HowlerManager";
import SongPlayService from "@/helpers/services/SongPlayService";
import Timeout from "smart-timeout";

const playTrackingTime = 30000; // 30 seconds to count as a play
const seekInterval = 250;

export default {
  namespaced: true,
  state: {
    debug: false,
    currentSong: null,
    isPlaying: false,
    isMuted: false,
    currentSongSeek: 0,
    currentVolume: HowlerManager.getVolume(),
    currentSongDuration: 0,
    autoplayEnabled: true, // Default to true, enforced in radio mode
    isShuffleEnabled: false,
    repeatMode: 'none', // Possible values: 'none', 'one', 'all'
  },
  mutations: {
    resetPlayer(state) {
      state.currentSong = null;
      state.isPlaying = false;
      state.currentSongSeek = 0;
      state.currentSongDuration = 0;
    },
    cycleRepeatMode(state) {
      if (!state.autoplayEnabled && state.repeatMode !== 'none') {
        console.warn('Autoplay must be enabled to set repeat mode to one or all.');
        return;
      }

      if (state.repeatMode === 'none') {
        state.repeatMode = 'one';
        state.autoplayEnabled = true;
      } else if (state.repeatMode === 'one') {
        state.repeatMode = 'all';
        state.autoplayEnabled = true;
      } else {
        state.repeatMode = 'none';
      }
    },
    toggleShuffle(state) {
      state.isShuffleEnabled = !state.isShuffleEnabled;
    },
    toggleAutoplay(state, queueMode) {
      if (queueMode === 'queue') {
        state.autoplayEnabled = !state.autoplayEnabled;

        if (!state.autoplayEnabled && state.repeatMode !== 'none') {
          state.repeatMode = 'none';
        }
      } else if (queueMode === 'radio') {
        state.autoplayEnabled = true; // Enforce autoplay in radio mode
      }
    },
    switchToRadioMode(state) {
      state.autoplayEnabled = true;
      state.isShuffleEnabled = false;
      state.repeatMode = 'none';
    },
    setCurrentSong(state, song) {
      if (state.debug) console.log('Mutation: setCurrentSong', { song });
      Vue.set(state, "currentSong", song);
    },
    setIsPlaying(state, isPlaying) {
      if (state.debug) console.log('Mutation: setIsPlaying', { isPlaying });
      state.isPlaying = isPlaying;
    },
    updateSongSeek(state, seek) {
      state.currentSongSeek = seek;
    },
    setIsMuted(state, isMuted) {
      if (state.debug) console.log('Mutation: setIsMuted', { isMuted });
      state.isMuted = isMuted;
    },
    updateVolume(state, volume) {
      if (state.debug) console.log('Mutation: updateVolume', { volume });
      state.currentVolume = volume;
    },
    setMute(state, isMuted) {
      if (state.debug) console.log('Mutation: setMute', { isMuted });
      state.isMuted = isMuted;
      HowlerManager.toggleMute(isMuted);
    },
    setVolume(state, volume) {
      if (state.debug) console.log('Mutation: setVolume', { volume });
      state.currentVolume = volume;
      HowlerManager.updateVolume(volume);
    },
    setSongDuration(state, duration) {
      state.currentSongDuration = duration;
    }
  },
  actions: {
    prependAndPlaySong({ commit, dispatch, state, rootState }, song) {
      if (rootState.atmoQueues.queueMode !== 'queue') return;

      // Add the song to the atmoQueue
      dispatch('atmoQueues/addSongToAtmoQueue', song, { root: true })
        .then(() => {
          // Play the song after it's added to the queue
          dispatch('playSong', song);
        })
        .catch(error => {
          console.error('Error adding and playing song:', error);
        });
    },
    setCurrentSongSeek({ commit, state }, seekPosition) {
      const howl = HowlerManager.getHowl(state.currentSong);
      if (howl) {
        howl.seek(seekPosition);
        commit("updateSongSeek", seekPosition);
      }
    },
    updateSongHowls({ commit, state }, songs) {
      const validSongs = songs.filter(song => song && song.id);
      if (state.debug) console.log('Action: updateSongHowls', { validSongs });

      validSongs.forEach(song => {
        HowlerManager.createHowl(song);
      });
    },
    playSong({ commit, dispatch, state, rootState }, song) {
      if (state.debug) console.log('Action: playSong', { song });

      const howl = HowlerManager.createHowl(song);
      if (!howl) return;

      const isSameSong = state.currentSong && state.currentSong.id === song.id;

      if (isSameSong && !state.isPlaying) {
        howl.play();
        commit("setIsPlaying", true);
        dispatch("startSeekPolling");
        dispatch("startPlayTrackingTimer");
      } else {
        dispatch("stopCurrentSong");
        commit("setCurrentSong", song);

        howl.play();
        commit("setIsPlaying", true);

        howl.on('play', () => {
          console.log('Howl onPlay event triggered');
          dispatch("startSeekPolling");
          dispatch("startPlayTrackingTimer");
        });
      }
    },
    async pauseSong({ commit, state }) {
      const { currentSong } = state;
      if (state.debug) console.log('Action: pauseSong', { currentSong });
      if (currentSong) {
        HowlerManager.pauseHowl(currentSong);
        commit("setIsPlaying", false);
        Timeout.pause("current-song-seek-polling");
        Timeout.pause("current-song-play-tracking");
      }
    },
    stopCurrentSong({ commit, state }) {
      const { currentSong } = state;
      if (state.debug) console.log('Action: stopCurrentSong', { currentSong });
      if (currentSong) {
        HowlerManager.stopHowl(currentSong);
        commit("setIsPlaying", false);
        Timeout.clear("current-song-seek-polling");
        Timeout.clear("current-song-play-tracking");
      }
    },
    startSeekPolling({ commit, dispatch, state }) {
      Timeout.clear("current-song-seek-polling");

      if (state.isPlaying) {
        Timeout.set(
          "current-song-seek-polling",
          () => {
            const howl = HowlerManager.getHowl(state.currentSong);
            if (howl) {
              const seek = howl.seek();
              const duration = howl.duration();
              commit("updateSongSeek", seek);
              commit("setSongDuration", duration);
              dispatch("startSeekPolling");
            } else {
              console.warn('Howl instance not found for current song.');
            }
          },
          seekInterval
        );
      }
    },
    startPlayTrackingTimer({ state, rootState, dispatch }) {
      Timeout.set(
        "current-song-play-tracking",
        async () => {
          const currentSong = state.currentSong;
          const currentUserId = rootState.currentUserId;
          if (state.debug) console.log('Play Tracking Timer triggered', { currentSong, currentUserId });
          if (currentSong) {
            await dispatch("trackSongPlay", { currentSong, currentUserId });
          }
        },
        playTrackingTime
      );
    },
    async trackSongPlay({ dispatch, state }, { currentSong, currentUserId }) {
      if (state.debug) console.log('Action: trackSongPlay', { currentSong, currentUserId });
      const songPlay = await SongPlayService.fetchSongPlayCount(currentSong.id);
      if (state.debug) console.log('trackSongPlay: fetchSongPlayCount result', { songPlay });

      if (!songPlay) {
        if (state.debug) console.log('trackSongPlay: creating new song play record');
        await SongPlayService.createSongPlay(currentSong.id, currentUserId);
      } else {
        if (state.debug) console.log('trackSongPlay: updating existing song play record');
        await SongPlayService.updateSongPlay(currentSong.id, songPlay.play_count);
        await SongPlayService.handleUserSongPlay(currentSong.id, currentUserId);
      }
    },
    async changeVolume({ commit, state }, volume) {
      if (state.debug) console.log('Action: changeVolume', { volume });
      HowlerManager.updateVolume(volume);
      commit("updateVolume", volume);
    },
    toggleMute({ commit, state }) {
      const newIsMuted = !state.isMuted;
      if (state.debug) console.log('Action: toggleMute', { newIsMuted });
      HowlerManager.toggleMute(newIsMuted);
      commit("setIsMuted", newIsMuted);
    },
    playNextTrack({ dispatch, commit, state, rootState }, loopToStart = false) {
      const queue = rootState.atmoQueues.activeQueue || [];
      const currentIndex = queue.findIndex(song => song.id === state.currentSong.id);

      if (currentIndex === -1) return;

      let newIndex = currentIndex + 1;

      if (newIndex >= queue.length) {
        if (rootState.atmoQueues.queueMode === 'queue' && (state.repeatMode === 'all' || loopToStart)) {
          newIndex = 0;
        } else {
          if (!state.autoplayEnabled) {
            commit('resetPlayer');
            return;
          }
        }
      }

      const newSong = queue[newIndex];
      if (newSong) {
        dispatch("playSong", newSong);
      } else {
        commit('resetPlayer');
      }
    },
    changeTrack({ dispatch, state, rootState }, increment) {
      const queue = rootState.atmoQueues.activeQueue || [];
      let newIndex;

      if (state.debug) console.log("Current queue:", queue);
      if (state.debug) console.log("Current song:", state.currentSong);
      if (state.debug) console.log("Increment value:", increment);

      if (rootState.atmoQueues.queueMode === 'queue' && state.isShuffleEnabled) {
        if (state.debug) console.log("Shuffle is enabled, selecting random track...");
        let attempt = 0;
        do {
          newIndex = Math.floor(Math.random() * queue.length);
          if (state.debug) console.log(`Shuffle attempt ${++attempt}: newIndex = ${newIndex}, selected song ID = ${queue[newIndex]?.id}`);
        } while (queue[newIndex].id === state.currentSong.id && queue.length > 1);
      } else {
        const currentIndex = queue.findIndex(song => song.id === state.currentSong.id);
        if (state.debug) console.log("Current song index in queue (based on ID):", currentIndex);

        if (currentIndex === -1) {
          if (state.debug) console.warn("Current song not found in the queue by ID. Aborting track change.");
          return;
        }

        newIndex = currentIndex + increment;
        if (state.debug) console.log("New index after increment:", newIndex);
      }

      const newSong = queue[newIndex];

      if (newSong) {
        if (state.debug) console.log("Dispatching playSong with the new song...");
        dispatch("playSong", newSong);
      } else {
        if (state.debug) console.warn('No song found to play.');
      }
    },
    async switchToRadioMode({ commit, dispatch }) {
      commit('switchToRadioMode');
      await dispatch('playNextTrack'); // Automatically play the next track in radio mode
    }
  }
};
