<template>
  <b-modal
    id="atmo-radio-results-modal"
    ref="atmoRadioResultsRef"
    v-model="$store.getters['atmoRadioStations/atmoRadioSearchResultsOpen']"
    title="Search Results"
    size="lg"
    modal-class="atmo-modal atmo-radio-results-modal"
    hide-header
    hide-footer
    centered
  >
    <header class="atmo-modal__header">
      <span class="atmo-modal__header-side">
        <img class="atmo-modal__close" src="@/assets/images/icons/close--light.png" @click="closeRadioStationModal()" />
      </span>
      <div class="atmo-page-header-container">
        <h2 class="atmo-page-header">Search Results</h2>
      </div>
      <span class="atmo-modal__header-side" />
    </header>

    <div class="atmo-radio-results-modal__body">
      <div class="results-categories-container">
        <div class="results-categories-container__results-category" @click="selectCategory('all')">All</div>
        <div class="results-categories-container__results-category" @click="selectCategory('artists')">Artists</div>
        <div class="results-categories-container__results-category" @click="selectCategory('albums')">Albums</div>
        <div class="results-categories-container__results-category" @click="selectCategory('songs')">Songs</div>
        <div class="results-categories-container__results-category" @click="selectCategory('moods')">Moods</div>
        <div class="results-categories-container__results-category" @click="selectCategory('genres')">Genres</div>
        <div class="results-categories-container__results-category" @click="selectCategory('occasions')">Occasions</div>
      </div>

      <div class="results-container">
        <perfect-scrollbar>
          <!-- Show message when no results are found -->
          <div v-if="filteredResults.length === 0" class="no-results">No results found</div>

          <!-- Show results if they exist -->
          <div v-else class="results-container__results-wrap" v-for="(result, index) in filteredResults" :key="index" @click="handleStationClick(result)">
            <div class="results-wrap__result-wrap">
              <div class="result-wrap__image-wrap">
                <img class="image-wrap__image" :src="result.image_url || defaultImage" alt="Result image" />
              </div>
              <div class="result-wrap__details-wrap">
                <div class="details-wrap__name">{{ result.name }}</div>
                <div class="details-wrap__category">{{ result.category }}</div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      selectedCategory: "all",
      defaultImage: require("@/assets/images/default_pic.jpg"),
    };
  },
  computed: {
    stationSongs() {
      return this.$store.getters["atmoRadioStations/stationSongs"];
    },
    // Fetch all search results from Vuex store
    searchResults() {
      return this.$store.getters["atmoRadioStations/searchResults"];
    },
    // Filter results based on selected category
    filteredResults() {
      if (this.selectedCategory === "all") {
        // Combine all categories into a single array for 'All'
        return [
          ...this.searchResults.artists.map((artist) => ({
            ...artist,
            category: "Artists",
          })),
          ...this.searchResults.albums.map((album) => ({
            ...album,
            category: "Albums",
          })),
          ...this.searchResults.songs.map((song) => ({
            ...song,
            category: "Songs",
          })),
          ...this.searchResults.moods.map((mood) => ({
            ...mood,
            category: "Moods",
          })),
          ...this.searchResults.genres.map((genre) => ({
            ...genre,
            category: "Genres",
          })),
          ...this.searchResults.occasions.map((occasion) => ({
            ...occasion,
            category: "Occasions",
          })),
        ];
      } else {
        // Filter results based on specific category
        return this.searchResults[this.selectedCategory].map((item) => ({
          ...item,
          category: this.capitalizeCategory(this.selectedCategory),
        }));
      }
    },
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category;
    },
    async switchToRadioMode() {
      if (this.stationSongs.length > 0) {
        // Set the queue mode to 'radio'
        await this.$store.dispatch("atmoQueues/setQueueMode", "radio");
        console.log("Queue mode set to:", this.$store.state.atmoQueues.queueMode);

        // Commit the switchToRadioMode mutation to configure the player for radio mode
        this.$store.commit("player/switchToRadioMode");
        console.log("Radio mode configured: autoplay enabled, shuffle off, repeat off");

        // Set the active queue to the station songs
        this.$store.commit("atmoQueues/setActiveQueue", this.stationSongs);

        // Ensure Howl instances are loaded for all songs in the queue
        await this.$store.dispatch("player/updateSongHowls", this.stationSongs);

        // Now play the first song in the station
        this.$store.dispatch("player/playSong", this.stationSongs[0]);
      }
    },
    async handleStationClick(result) {
      const stationType = result.category.toLowerCase(); // Assuming 'category' holds the type like 'Mood', 'Artist', etc.
      const stationQuery = result.name;
      const userId = this.$store.state.user.id; // Assuming you have a user ID in your store
      const resourceId = result.id;

      try {
        // Dispatch the Vuex action to handle the station creation/retrieval and fetching of songs
        await this.$store.dispatch("atmoRadioStations/fetchUserRadioStationSongs", {
          station_id: null,
          station_type: stationType,
          station_query: stationQuery,
          resource_id: resourceId,
          user_id: userId,
          length: 10,
        });

        // Switch to radio mode and hide the results
        this.switchToRadioMode();
        this.$refs.atmoRadioResultsRef.hide();
      } catch (error) {
        console.error("Error fetching or creating radio station:", error);
      }
    },
    closeRadioStationModal() {
      this.$store.commit("atmoRadioStations/toggleSearchResultsModal");
    },
    capitalizeCategory(category) {
      return category.charAt(0).toUpperCase() + category.slice(1);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/stylesheets/_variables.scss";

#atmo-radio-results-modal {
  .modal-content {
    border-radius: 5px;
    border: none;
    width: 100vw;
    height: 97vh;
  }

  .ps {
    min-height: 70vh;
  }
}

.atmo-radio-results-modal {
  .modal-lg.modal-dialog {
    max-width: 100vw;
    margin: 1rem;
  }

  .atmo-modal__header {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 2rem;
  }

  .atmo-modal__header-side {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;

    img {
      height: 1.5rem;
    }
  }

  .atmo-page-header-container {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .results-categories-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;

    .results-categories-container__results-category {
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 700;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  }

  .results-container {
    width: 100%;
    padding: 0rem 3rem 0rem 3rem;

    .no-results {
      text-align: center;
      font-size: 1.2rem;
      color: white;
      padding: 2rem;
    }
  }

  .results-container__results-wrap {
    // border: 1px solid green;
  }

  .results-container__results-wrap + .results-container__results-wrap {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  .results-wrap__result-wrap {
    display: flex;
    gap: 2rem;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
  }

  .result-wrap__image-wrap {
    img {
      height: 5rem;
      width: 5rem;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  .result-wrap__details-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .details-wrap__name {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}
</style>
