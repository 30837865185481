<template>
    <div class="atmo-library-album-songs">
        <div class="atmo-library-album-songs__heading-container">
            <div class="heading-container__title">Title</div>
            <div>Time</div>
            <div>Genre</div>
        </div>
        <perfect-scrollbar v-if="scrollEnabled">
            <!-- <div v-for="(song, index) in songs" :key="index" @click="$store.dispatch('player/prependAndPlaySong', sampleSong)" -->
            <div v-for="(song, index) in songs" :key="index"
                :class="index % 2 !== 0 ? 'atmo-library-album-songs__song-row --dark' : 'atmo-library-album-songs__song-row --light'">
                <div class="song-row__song-title-wrap">
                    <song-actions-popover v-if="!isAddSongsMode()" :song="song" :actions="songActions" />
                    <input v-if="componentLocation == 'liveListeningAdd'" type="checkbox"
                        :class="index % 2 !== 0 ? 'check-dark' : 'check-light'" :name="song.name" :value="song.name"
                        @click="addSongToLiveListeningPendingSongs(song)"
                        v-on:change="handleCheckboxChange(song, $event)">
                    <input v-if="componentLocation == 'addSongsModal'" type="checkbox"
                        :class="index % 2 !== 0 ? 'check-dark' : 'check-light'" :name="song.name" :value="song.name"
                        @click="addSongToAddSongsModalList(song)" v-on:change="handleCheckboxChange(song, $event)">
                    <div class="song-title-wrap__song-title" v-if="componentLocation == 'atmoLibrary'" @click="$store.dispatch('player/prependAndPlaySong', song)">{{ song.name }}</div>
                    <div class="song-title-wrap__song-title" v-else>{{ song.name }}</div>
                </div>
                <div>{{ getSongDuration(song) }}</div>
                <div>{{ song.song_profile.genre.name }}</div>
                <div v-if="componentLocation == 'liveListeningQueue'" @click="removeSongFromSession(song)"
                    class="remove-song">Remove</div>
            </div>
        </perfect-scrollbar>
        <div v-else v-for="(song, index) in songs" :key="index"
            :class="index % 2 !== 0 ? 'atmo-library-album-songs__song-row --dark' : 'atmo-library-album-songs__song-row --light'">
            <div class="song-row__song-title-wrap">
                <song-actions-popover v-if="!isAddSongsMode()" :song="song" :actions="songActions" />
                <input v-if="componentLocation == 'liveListeningAdd'" type="checkbox"
                    :class="index % 2 !== 0 ? 'check-dark' : 'check-light'" :name="song.name" :value="song.name"
                    @click="addSongToLiveListeningPendingSongs(song)"
                    v-on:change="handleCheckboxChange(song, $event)">
                <input v-if="componentLocation == 'addSongsModal'" type="checkbox"
                    :class="index % 2 !== 0 ? 'check-dark' : 'check-light'" :name="song.name" :value="song.name"
                    @click="addSongToAddSongsModalList(song)" v-on:change="handleCheckboxChange(song, $event)">
                <div class="song-title-wrap__song-title" v-if="componentLocation == 'atmoLibrary'" @click="$store.dispatch('player/prependAndPlaySong', song)">{{ song.name }}</div>
                <div class="song-title-wrap__song-title" v-else>{{ song.name }}</div>
            </div>
            <div>{{ getSongDuration(song) }}</div>
            <div>{{ song.song_profile.genre.name }}</div>
            <div v-if="componentLocation == 'liveListeningQueue'" @click="removeSongFromSession(song)"
                class="remove-song">Remove</div>
        </div>
    </div>
</template>

<script>
import SongActionsPopover from '@/components/song-actions-popover';
import { callSongReferencesDelete } from '@/helpers/axiosCalls';
import { getSongDuration } from '@/helpers/utilityFunctions';

export default {
    components: { SongActionsPopover },
    props: {
        scrollEnabled: {
            type: Boolean,
            default: false
        },
        componentLocation: {
            type: String,
            default: null
        },
        songs: {
            type: Array,
            default: null
        },
        songActions: {
            type: Array,
            default: () => [
                'share-song-as-post',
                'share-song-as-message',
                'add-to-queue',
                'add-to-playlist'
            ]
        }
    },
    data() {
        return {
        };
    },
    computed: {
        fullCollection() {
            if (this.showNewReleases) {
                return this.songs.filter(song => song.isNew);
            } else {
                return this.songs;
            }
        }
    },
    created() {},
    methods: {
        getSongDuration(song) {
            return getSongDuration(song);
        },
        isAddSongsMode() {
            if (this.componentLocation == 'liveListeningAdd' || this.componentLocation == 'addSongsModal') {
                return true;
            } else {
                return false;
            }
        },
        handleCheckboxChange(song, event) {
            if (this.componentLocation == 'liveListeningAdd') {
                if (event.target.checked) {
                    this.addSongToLiveListeningPendingSongs(song);
                } else {
                    const songIsInPendingSongs = this.songIsInArray(this.$store.state.liveListeningRooms.liveListeningChatRoomPendingSongs, song.id);

                    if (songIsInPendingSongs) {
                        const updatedPendingSongs = this.$store.state.liveListeningRooms.liveListeningChatRoomPendingSongs.filter(pendingSong => pendingSong.id !== song.id);
                        this.$store.commit('liveListeningRooms/setLiveListeningChatRoomPendingSongs', updatedPendingSongs);
                    }

                }
            } else if (this.componentLocation == 'addSongsModal') {
                if (event.target.checked) {
                    this.addSongToAddSongsModalList(song);
                } else {
                    const songIsInAddSongsModalList = this.songIsInArray(this.$store.state.addSongsModalList, song.id);

                    if (songIsInAddSongsModalList) {
                        const updatedPendingSongs = this.$store.state.addSongsModalList.filter(pendingSong => pendingSong.id !== song.id);
                        this.$store.commit('setAddSongsModalList', updatedPendingSongs);
                    }

                }
            }
        },
        findSongReferenceBySongId(array, songId) {
            return array.find(songReference => songReference.song_id === songId);
        },
        songIsInArray(array, songId) {
            const result = array.find(arraySong => arraySong.id === songId);

            if (result) {
                return true
            } else {
                return false;
            }
        },
        songIsReferenceArray(array, songId) {
            const result = array.find(arraySong => arraySong.song_id === songId);

            if (result) {
                return true
            } else {
                return false;
            }
        },
        removeSongFromSession(song) {
            const songReference = this.findSongReferenceBySongId(this.$store.state.liveListeningRooms.liveListeningChatRoomSongs, song.id);

            callSongReferencesDelete(songReference.id)
                .then(() => {
                    this.$store.dispatch("liveListeningRooms/getLiveListeningChatRoomSongs", this.$store.state.liveListeningRooms.liveListeningChatRoom.id);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        addSongToLiveListeningPendingSongs(song) {
            const songIsInPendingSongs = this.songIsInArray(this.$store.state.liveListeningRooms.liveListeningChatRoomPendingSongs, song.id);
            const songIsLiveListeningSongs = this.songIsReferenceArray(this.$store.state.liveListeningRooms.liveListeningChatRoomSongs, song.id);
            if (!songIsInPendingSongs && !songIsLiveListeningSongs) {
                const pendingSongs = [...this.$store.state.liveListeningRooms.liveListeningChatRoomPendingSongs, song];
                this.$store.commit('liveListeningRooms/setLiveListeningChatRoomPendingSongs', pendingSongs);
            }
        },
        addSongToAddSongsModalList(song) {
            const songIsInPendingSongs = this.songIsInArray(this.$store.state.addSongsModalList, song.id);
            if (!songIsInPendingSongs) {
                const pendingSongs = [...this.$store.state.addSongsModalList, song];
                this.$store.commit('setAddSongsModalList', pendingSongs);
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/stylesheets/_variables.scss";

.atmo-library-album-songs {
    width: 100%;
    height: 100%;

    //https://www.sliderrevolution.com/resources/css-checkbox/
    input[type="checkbox"] {
        position: relative;
        width: 1.2em;
        height: 1.2em;
        color: #363839;
        border-radius: 4px;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        background: $atmo-purple--dark;

        &::before {
            position: absolute;
            content: '';
            display: block;
            top: 2px;
            left: 6px;
            width: 4px;
            height: 10px;
            border-style: solid;
            border-color: $atmo-purple--dark;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
        }

        &:checked {
            color: $atmo-purple--dark;
            border-color: $atmo-blue--medium;
            background: $atmo-blue--medium;

            &::before {
                opacity: 1;
            }

            ~label::before {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }
    }

    .check-dark {
        border: 1px solid rgba(white, 0.5);
    }

    .atmo-library-album-songs__heading-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    .heading-container__title {
        margin-left: 3rem;
    }

    .atmo-library-album-songs__song-row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 1rem;
        cursor: pointer;
        font-weight: 500;
        position: relative;
        // border-radius: 5px;

        &.--light {
            background: linear-gradient(to right, rgba(255, 255, 255, .3), rgba(255, 255, 255, 0))
        }

        .song-row__song-title-wrap {
            position: relative;
            display: flex;
            align-items: center;

            .song-actions-popover {
                position: absolute;
            }

            .popover {
                width: 10rem;
            }

            .song-title-wrap__song-title {
                margin-left: 2rem;
            }
        }

        .remove-song {
            display: none;
        }

        &:hover {
            .remove-song {
                display: flex;
                position: absolute;
                top: 1rem;
                right: 1rem;
            }
        }
    }
}
</style>