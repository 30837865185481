<template>
  <div class="hud-footer">
    <!-- Seek Control -->
    <div class="player-bar__seek-control-wrapper" v-if="!$store.state.videoPlayer.modalOrBackgroundVidPlaying">
      <range-slider v-if="currentSong && currentSeek !== null" :value="sliderSeek"
        class="player-bar__range-slider player-bar__range-slider--seek" :min="0" :max="currentDuration" step="0.25"
        @input="seekMoving" @change="changeSeek" />
    </div>
    <!-- Player Controls -->
    <div class="player-bar__container" :class="{ 'player-bar__container--listening': $store.state.AMIProcessing }">
      <div v-if="!$store.state.videoPlayer.modalOrBackgroundVidPlaying" class="player-bar__left-items atmo-flex-child--equal-width">
        <a v-b-modal.atmo-queue class="player-bar__queue-toggle">
          <img class="player-bar__icon" src="@/assets/images/icons/queue.png" />
        </a>
      </div>
      <div v-if="queueMode === 'queue' && !$store.state.videoPlayer.modalOrBackgroundVidPlaying" class="player-bar__song-controls">
        <div class="player-bar__autoplay-toggle">
          <a @click="$store.commit('player/toggleAutoplay', queueMode)">
            <img v-if="autoplayEnabled" src="@/assets/images/icons/autoplay-on.png"
              class="player-bar__icon player-bar__icon--small autoplay-on" alt="Autoplay On" />
            <img v-else src="@/assets/images/icons/autoplay-off.png"
              class="player-bar__icon player-bar__icon--small autoplay-off" alt="Autoplay Off" />
          </a>
        </div>
        <div class="player-bar__shuffle-toggle">
          <a @click="$store.commit('player/toggleShuffle')">
            <img v-if="isShuffleEnabled" src="@/assets/images/icons/shuffle.png"
              class="player-bar__icon player-bar__icon--small shuffle-on" alt="Shuffle On" />
            <img v-else src="@/assets/images/icons/shuffle.png"
              class="player-bar__icon player-bar__icon--small shuffle-off" alt="Shuffle Off" />
          </a>
        </div>
        <div class="player-bar__repeat-toggle">
          <a @click="$store.commit('player/cycleRepeatMode')">
            <img v-if="repeatMode === 'none'" src="@/assets/images/icons/repeat.png"
              class="player-bar__icon player-bar__icon--small repeat-none" alt="Repeat None" />
            <img v-else-if="repeatMode === 'one'" src="@/assets/images/icons/repeat-one.png"
              class="player-bar__icon player-bar__icon--small repeat-one" alt="Repeat One" />
            <img v-else-if="repeatMode === 'all'" src="@/assets/images/icons/repeat.png"
              class="player-bar__icon player-bar__icon--small repeat-all" alt="Repeat All" />
          </a>
        </div>
      </div>
      <div v-if="!$store.state.videoPlayer.modalOrBackgroundVidPlaying"
        class="player-bar__control-groups atmo-flex-child--equal-width">
        <div class="player-bar__volume atmo-flex-child--equal-width">
          <img v-show="!isMuted" class="player-bar__icon player-bar__icon--small" src="@/assets/images/icons/sound.png"
            @click="toggleMute" />
          <img v-show="isMuted" class="player-bar__icon player-bar__icon--small"
            src="@/assets/images/icons/sound--muted.png" @click="toggleMute" />
          <range-slider :value="sliderVolume" class="player-bar__range-slider player-bar__range-slider--volume" :min="0"
            :max="100" step="1" @input="changeVolume" />
        </div>
        <div class="player-bar__main-controls atmo-flex-child--equal-width">
          <a class="player-bar__previous">
            <img src="@/assets/images/icons/backward.png" class="player-bar__icon player-bar__icon--small"
              @click="playPreviousTrack" />
          </a>
          <a class="player-bar__play-pause">
            <img v-show="isPlaying" class="player-bar__icon player-bar__icon--play-pause"
              src="@/assets/images/icons/pause.png" @click="pause" />
            <img v-show="!isPlaying" class="player-bar__icon player-bar__icon--play-pause"
              :class="{ 'player-bar__icon--disabled': !currentSong }" src="@/assets/images/icons/play.png"
              @click="play" />
          </a>
          <a class="player-bar__next">
            <img src="@/assets/images/icons/forward.png" class="player-bar__icon player-bar__icon--small"
              @click="playNextTrack" />
          </a>
        </div>
        <div class="player-bar__song-info atmo-flex-child--equal-width">
          <h2 class="player-bar__song-name">
            <router-link v-if="currentSong" :to="{
              name: 'songs.show',
              params: { songId: currentSong.id }
            }">
              {{ currentSong.name }}
            </router-link>
          </h2>
          <h3 class="player-bar__artist-name">
            <router-link v-if="currentSong" :to="{
              name: 'artist_profiles.show',
              params: { artistProfileId: currentSong.artist_profile.id }
            }">
              {{ (currentSong.artist_profile.user || {}).name }}
            </router-link>
          </h3>
        </div>
      </div>
      <div class="player-bar__queue-mode" v-if="!$store.state.videoPlayer.modalOrBackgroundVidPlaying">
        <img v-if="queueMode === 'liveListening'" src="@/assets/images/live_listening.png"
          class="player-bar__icon-nonclickable player-bar__icon-nonclickable--small" alt="Live Listening Mode" />
        <img v-else-if="queueMode === 'queue'" src="@/assets/images/icons/queue-mode.png"
          class="player-bar__icon-nonclickable player-bar__icon-nonclickable--small" alt="Queue Mode" />
        <div v-else-if="queueMode === 'radio'" class="player-bar__radio-mode">
          <img src="@/assets/images/icons/radio-station-mode.png"
            class="player-bar__icon-nonclickable player-bar__icon-nonclickable--small" alt="Radio Mode" />
          <a @click="switchToQueueMode">
            Exit Radio
          </a>
        </div>
      </div>

      <div v-if="$store.state.videoPlayer.backgroundVidPlaying" class="video-controls-container">
        <div style="cursor:pointer" @click="backward()"><img class="hud-header__icon icon-image"
            src="@/assets/images/icons/video-back-10.png"></div>
        <div class="video-controls-icon">
          <div class="icon-container" @click="toggleMainContent()" style="cursor:pointer">
            <img v-if="$store.state.mainContentDisplayed" class="hud-header__icon hide-image"
              src="@/assets/images/icons/video-hide.png">
            <img v-else class="hud-header__icon show-image" src="@/assets/images/icons/video-show.png">
          </div>
        </div>
        <a class="player-bar__play-pause">
          <img v-if="$store.state.videoPlayer.playPausePushed" class="player-bar__icon player-bar__icon--play-pause"
            src="@/assets/images/icons/play.png" @click="playPauseVideo()">
          <img v-else class="player-bar__icon player-bar__icon--play-pause" src="@/assets/images/icons/pause.png"
            @click="playPauseVideo()">
        </a>
        <div class="video-controls-icon">
          <div class="icon-container" @click="hideVideo()" style="cursor:pointer">
            <img class="hud-header__icon icon-image" src="@/assets/images/icons/close_popup.png">
          </div>
        </div>
        <div style="cursor:pointer" @click="forward()"><img class="hud-header__icon icon-image"
            src="@/assets/images/icons/video-forward-10.png"></div>
      </div>
      <!-- Track Times -->
      <div class="player-bar__right-items atmo-flex-child--equal-width"
        v-if="!$store.state.videoPlayer.modalOrBackgroundVidPlaying">
        <div v-if="currentSong" class="player-bar__track-times-display">
          <span class="player-bar__current-time">{{ formattedSeek }}</span>
          <span class="player-bar__track-times-delimiter">/</span>
          <span class="player-bar__total-time">{{ formattedDuration }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RangeSlider from "vue-range-slider";

export default {
  components: { RangeSlider },
  computed: {
    repeatMode() {
      return this.$store.state.player.repeatMode;
    },
    isShuffleEnabled() {
      return this.$store.state.player.isShuffleEnabled;
    },
    autoplayEnabled() {
      return this.$store.state.player.autoplayEnabled;
    },
    queueMode() {
      return this.$store.state.atmoQueues.queueMode;
    },
    activeQueue() {
      return this.$store.state.atmoQueues.activeQueue;
    },
    currentSong() {
      return this.$store.state.player.currentSong;
    },
    currentSeek() {
      return this.$store.state.player.currentSongSeek;
    },
    currentDuration() {
      return this.$store.state.player.currentSongDuration;
    },
    isPlaying() {
      return this.$store.state.player.isPlaying;
    },
    isMuted() {
      return this.$store.state.player.isMuted;
    },
    sliderVolume() {
      return this.isMuted ? 0 : this.$store.state.player.currentVolume * 100;
    },
    sliderSeek() {
      return this.seekIsActive ? this.activeSeekValue : this.currentSeek;
    },
    formattedSeek() {
      return toHHMMSS(this.currentSeek);
    },
    formattedDuration() {
      return toHHMMSS(this.currentDuration);
    }
  },
  data() {
    return {
      seekIsActive: false,
      activeSeekValue: null,
    };
  },
  methods: {
    async switchToQueueMode() {
      // Stop the current song if it's playing
      if (this.isPlaying) {
        await this.$store.dispatch('player/pauseSong');
      }

      // Clear the current song
      this.$store.commit('player/setCurrentSong', null);

      // Set the queue mode to 'queue'
      await this.$store.dispatch('atmoQueues/setQueueMode', 'queue');
      console.log('Queue mode set to:', this.$store.state.atmoQueues.queueMode);

      // Disable autoplay so no song starts automatically
      this.$store.commit('player/toggleAutoplay', 'queue');
      console.log('Autoplay disabled:', this.$store.state.player.autoplayEnabled);

      // Fetch and set the active queue
      await this.$store.dispatch('atmoQueues/getAtmoQueue');
      const activeQueue = this.$store.getters['atmoQueues/activeQueue'];

      // Set the active queue (it should now be the same as what getAtmoQueue returned)
      this.$store.commit('atmoQueues/setActiveQueue', activeQueue);

      console.log('Switched to queue mode with active queue:', activeQueue);
    },
    seekMoving(newSliderValue) {
      this.activeSeekValue = newSliderValue;
      this.seekIsActive = true;
    },
    changeSeek(newSliderValue) {
      this.seekIsActive = false;
      this.$store.dispatch("player/setCurrentSongSeek", newSliderValue);
    },
    toggleMute() {
      this.$store.dispatch("player/toggleMute");
    },
    changeVolume(newSliderValue) {
      if (newSliderValue === 0) {
        this.$store.commit("player/setMute", true);
      } else {
        this.$store.commit("player/setVolume", newSliderValue / 100);
        this.$store.commit("player/setMute", false);
      }
    },
    togglePlay() {
      this.isPlaying ? this.pause() : this.play();
    },
    play() {
      let currentSong = this.currentSong;

      if (!currentSong && this.activeQueue.length > 0) {
        // If no song is currently selected, pick the first one in the activeQueue
        currentSong = this.activeQueue[0];
        this.$store.commit('player/setCurrentSong', currentSong);
      }

      if (currentSong) {
        this.$store.dispatch('player/playSong', currentSong);
      }
    },
    pause() {
      this.$store.dispatch("player/pauseSong");
    },
    playPreviousTrack() {
      this.$store.dispatch("player/changeTrack", -1);
    },
    playNextTrack() {
      this.$store.dispatch("player/changeTrack", 1);
    },
    backward() {
      this.skip(-15);
    },
    forward() {
      this.skip(15);
    },
    toggleMainContent() {
      this.$store.commit("videoPlayer/toggleMainContent");
    },
    playPauseVideo() {
      this.$store.commit("videoPlayer/playPauseVideo");
    },
    hideVideo() {
      this.$store.commit("videoPlayer/hideVideo");
      this.toggleIsBackgroundVidPlaying();
    },
    toggleIsBackgroundVidPlaying() {
      this.$store.commit("videoPlayer/toggleIsBackgroundVidPlaying");
    },
    skip(time) {
      const video = document.querySelector("video")
      video.currentTime = video.currentTime + time;
    }
  }
};

const toHHMMSS = (numberOfSeconds) => {
  if (typeof numberOfSeconds !== "number") {
    return "0:00";
  }
  const secNum = parseInt(numberOfSeconds, 10);
  const minutes = Math.floor(secNum / 60);
  const seconds = secNum - minutes * 60;
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};
</script>

<style lang="scss">
@import 'vue-range-slider/dist/vue-range-slider.css';
@import '~@/assets/stylesheets/_variables.scss';

$song-info-min-width: 400px;

.hud-footer {
  .video-controls-container {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    width: 100%;
  }

  .video-controls-icon {
    --border-width: 3px;
    display: inline-block;
    width: 43px;
    aspect-ratio: 1;
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $atmo-purple--dark;
    border-radius: 100%;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      cursor: pointer;
      z-index: -1;
      inset: 0;
      background: var(--c, linear-gradient(-270deg, $atmo-pink--medium 0%, $atmo-blue--light 100%));
      padding: var(--border-width);
      border-radius: 50%;
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    .icon-image {
      height: .8rem;
      width: auto;
      margin-bottom: 2px;
    }

    .show-image {
      height: 1.2rem;
      width: auto;
      margin-bottom: 2px;
    }

    .hide-image {
      margin-bottom: 1px;
      height: .9rem;
      width: auto;
      margin-bottom: 2px;
    }
  }
}

.player-bar {
  &__seek-control-wrapper {
    height: 9px;
  }

  &__range-slider {
    cursor: pointer;

    &--seek {
      width: 100%;
      padding: 0;
      padding-bottom: 13px;

      .range-slider-rail {
        background: none;
      }

      .range-slider-fill {
        background-image: linear-gradient(-270deg, $atmo-pink--medium 0%, $atmo-blue--light 100%);
      }

      .range-slider-knob {
        z-index: 1;
        width: 0.8rem;
        height: 0.8rem;
        box-shadow: 1px 0 2px 0 black;
        transition:
          width 100ms ease-in-out,
          height 100ms ease-in-out;
      }

      &:hover {
        .range-slider-rail {
          background-color: rgba($atmo-purple--light, 0.4);
        }

        .range-slider-knob {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    &--volume {
      width: 100px;

      .range-slider-rail,
      .range-slider-fill {
        height: 5px;
        border-radius: 5px;
      }

      .range-slider-fill {
        background-color: $atmo-blue--medium;
      }

      .range-slider-knob {
        width: 0.9rem;
        height: 0.9rem;
      }
    }
  }

  &__container {
    background-color: rgba($atmo-purple--extra-dark, 0.6);
    backdrop-filter: blur(5px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    min-height: 5rem;

    &--listening {
      box-shadow: inset 0px 0px 16px 4px $atmo-blue--medium;
    }
  }

  &__song-controls {
    cursor: pointer;
    display: flex;
    gap: .5rem;

    .player-bar__autoplay-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .player-bar__repeat-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .player-bar__shuffle-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .autoplay-on {
      width: 30px;
      height: 30px;
    }

    .autoplay-off {
      width: 30px;
      height: 30px;
      filter: brightness(.7);
    }

    .shuffle-on {
      width: 20px;
      height: 20px;
    }

    .shuffle-off {
      width: 20px;
      height: 20px;
      filter: brightness(.7);
    }

    .repeat-none {
      width: 20px;
      height: 20px;
      filter: brightness(.7);
    }

    .repeat-one {
      width: 20px;
      height: 20px;
    }

    .repeat-all {
      width: 20px;
      height: 20px;
    }
  }

  &__left-items {
    display: flex;
    justify-content: flex-start;
  }

  &__icon {
    cursor: pointer;
    width: 32px;
    height: 32px;

    &--small {
      width: 20px;
      height: 20px;
    }

    &--play-pause {
      width: 48px;
      height: 50px;
      margin-bottom: -8px;
    }
  }

  &__icon-nonclickable {
    &--small {
      width: 20px;
      height: 20px;
    }
  }

  &__radio-mode {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;

    a {
      cursor: pointer;
      color: white;
    }
  }

  &__control-groups {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__volume {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: $song-info-min-width;
  }

  &__main-controls {
    display: flex;
    align-items: center;
    padding: 0 40px;
  }

  &__play-pause {
    cursor: pointer;
    padding: 0 12px;

    &:hover {
      opacity: 0.8;
    }
  }

  &__song-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: $song-info-min-width;
  }

  &__song-name {
    font-size: 1.2em;
    font-weight: 300;
    margin-bottom: 8px;

    a {
      color: white;
    }
  }

  &__artist-name {
    font-size: 0.9em;

    a {
      color: $atmo-blue--medium;
    }
  }

  &__right-items {
    display: flex;
    justify-content: flex-end;
    align-self: flex-start;
  }

  &__track-times-display {
    display: flex;
    align-self: flex-start;
    font-weight: 300;
  }

  &__current-time {
    color: $atmo-blue--light;
  }

  &__track-times-delimiter {
    margin: 0 3px;
  }
}

@media (max-width: 40rem) {
  .player-bar {
    padding: 1rem;
    background: rgba(0, 0, 0, 0.6);

    .main-controls,
    .currently-playing,
    .volume {
      display: block;
      margin: 0 auto;
      padding: 0;
      width: 100%;
      text-align: center;
    }

    .main-controls,
    .volume {
      min-height: 3.5rem;
    }

    .currently-playing {
      min-height: 2.5rem;
    }

    .artist-name,
    .song-name {
      display: none;
    }

    .artist-song-mobile {
      display: block;
    }
  }
}
</style>
