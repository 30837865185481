<template>
  <video-background ref="videobackground" :src="this.$store.state.videoPlayer.videoBackgroundUrl"
    :poster="require(`@/assets/images/atmo_bg_2018.png`)" preload="false" :loop="false" :autoplay="false" :muted="isMuted"
    class="atmo-hud hud-container">
    <notifications group="player" position="bottom left" />
    <notifications group="vue-app" position="top center" data-cy="vue-app-notify" />
    <div v-if="user" class="hud-header">
      <div class="hud-header__button-group">
        <div v-if="!$store.state.videoPlayer.backgroundVidPlaying || $store.state.videoPlayer.mainContentDisplayed">
          <router-link :to="{ name: 'dash' }">
            <img src="@/assets/images/icons/atmo_logo.png" class="hud-header__icon hud-header__icon--wide">
          </router-link>
          <router-link :to="{ name: 'music' }">
            <img src="@/assets/images/icons/songs.png" class="hud-header__icon">
          </router-link>
        </div>
        <router-link
          v-if="!$store.state.videoPlayer.backgroundVidPlaying || $store.state.videoPlayer.mainContentDisplayed"
          :to="{ name: 'products.index' }">
          <img src="@/assets/images/icons/cart.png" class="hud-header__icon">
        </router-link>
        <div v-if="!$store.state.videoPlayer.backgroundVidPlaying || $store.state.videoPlayer.mainContentDisplayed">
          <atmo-search />
        </div>
      </div>
      <div class="hud-header__button-group">
        <div>
          <a @click="openBroadcastModal" style="cursor:pointer">
            <img class="hud-header__icon" src="@/assets/images/icons/broadcast.png">
          </a>
        </div>
        <div>
          <a @click="openLiveListeningIndex" style="cursor:pointer" id="live-listening-link">
            <notification-dot dot-id="live-listening-dot" notification-type="Live Listening Request"
              style="position:absolute; top:-29px; left:30px;"></notification-dot>
            <img class="hud-header__icon" src="@/assets/images/icons/live.png">
          </a>
        </div>
        <div
          v-if="user && (!$store.state.videoPlayer.backgroundVidPlaying || $store.state.videoPlayer.mainContentDisplayed)"
          title="My Profile">
          <router-link :to="{
    name: getProfileRouteName(),
    params: getProfileParams()
  }" id="user-link">
            <img class="hud-header__icon hud-header__icon--circular" :src="user.thumbImageUrl">
          </router-link>
        </div>
        <dropdown-menu :user="user"
          v-if="!$store.state.videoPlayer.backgroundVidPlaying || $store.state.videoPlayer.mainContentDisplayed" />
      </div>
    </div>

    <div v-if="isLoading" class="hud-row atmo-flex">
      <atmo-loading size="large" />
    </div>
    <!-- beginning of responsive UI -->
    <div v-else class="hud-row">
      <div v-if="user" id="HUD_LEFT" class="hud-sidebar">
        <div class="hud-sidebar__container">
          <mini-library v-if="user" />
          <atmo-ad v-if="user" />
        </div>
        <div class="hud-sidebar-border-line" />
      </div>

      <div class="hud-main-content">
        <!-- Load Content -->
        <!-- modals -->
        <live-listening-index />
        <live-listening-user />
        <atmo-library />
        <ami-options />
        <ami-testing />
        <atmo-queue />
        <broadcast />
        <music-profile />
        <atmo-ad-modal />
        <user-media-modal />
        <max-chat-modal />
        <atmo-radio-results />
        <chat-image-modal />
        <share-post-modal />
        <add-songs-modal />
        <user-accomplishments-modal />
        <div v-bind:class="{
    contenthidden: $store.state.videoPlayer.backgroundVidPlaying,
    content: !$store.state.videoPlayer.backgroundVidPlaying,
    contentwithbackground: $store.state.videoPlayer.backgroundVidPlaying && $store.state.videoPlayer.mainContentDisplayed
  }">
          <!-- hidden components -->
          <ami :logout="logout" :webSpeech="webSpeech" />
          <!-- container for current route -->
          <router-view />
        </div>
      </div>

      <div v-if="user" id="HUD_RIGHT" class="hud-sidebar">
        <div class="hud-sidebar-border-line" />
        <div class="hud-sidebar__container">
          <atmo-chat v-if="user" />
          <atmo-ad v-if="user" />
        </div>
      </div>
    </div>

    <div v-if="user" class="hud-footer">
      <player-bar />
    </div>
  </video-background>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import AtmoLibrary from '@/modals/atmo-library';
import AtmoQueue from '@/modals/atmo-queue';
import LiveListeningIndex from '@/modals/live-listening/index';
import LiveListeningUser from '@/modals/live-listening/user-queue';
import Broadcast from '@/modals/posts/broadcast';
import MusicProfile from '@/modals/music-profile';
import AmiOptions from '@/modals/ami-options';
import AmiTesting from '@/modals/ami-testing';
import AtmoLoading from '@/components/atmo-loading';
import PlayerBar from '@/components/player-bar';
import AtmoSearch from '@/components/atmo-search';
import MiniLibrary from '@/components/mini-library';
import AtmoChat from '@/components/atmo-chat';
import MaxChatModal from '@/modals/atmo-chat/max-chat-modal';
import AtmoAd from '@/components/atmo-ad';
import AtmoAdModal from '@/modals/atmo-ad-modal';
import UserMediaModal from '@/modals/user-media-modal';
import Ami from '@/components/ami';
import VideoBackground from 'vue-responsive-video-background-player';
import AtmoRadioResults from '@/modals/radio-search-results';
import ChatImageModal from '@/modals/atmo-chat/chat-image-modal';
import NotificationDot from '@/components/notifications/notification-dot';
import SharePostModal from '@/modals/posts/share-post-modal';
import AddSongsModal from '@/modals/add-songs-modal';
import UserAccomplishmentsModal from '@/modals/user-accomplishment-modal.vue';
import DropdownMenu from '@/components/atmo-hud/dropdown-menu.vue';


// Vue.component('video-background', VideoBackground);
export default {
  components: {
    AtmoLoading,
    AtmoLibrary,
    AmiOptions,
    AmiTesting,
    AtmoQueue,
    PlayerBar,
    MiniLibrary,
    AtmoSearch,
    AtmoChat,
    MaxChatModal,
    AtmoAd,
    LiveListeningIndex,
    LiveListeningUser,
    AtmoAdModal,
    Ami,
    Broadcast,
    MusicProfile,
    UserMediaModal,
    VideoBackground,
    AtmoRadioResults,
    ChatImageModal,
    NotificationDot,
    SharePostModal,
    AddSongsModal,
    UserAccomplishmentsModal,
    DropdownMenu
  },
  data: function () {
    return {
      webSpeech: null,
    };
  },
  created() {
    this.setSpeechRecognitionInstance();
    this.updateVideoUrl('https://atmosphere-development.s3.amazonaws.com/flowers.mp4');
  },
  mounted() {
    if (this.$refs.videobackground) {
      console.log("Mounted: Video background ref:", this.$refs.videobackground);
      this.$store.commit("videoPlayer/setVideoPlayerObject", this.$refs.videobackground);
    } else {
      console.error("Mounted: Video background ref is not available.");
    }
  },
  computed: {
    ...mapState(['user']),
    isLoading() {
      const routesThatDontNeedAuth = [
        'login',
        'signup'
      ];
      const needAuth = !routesThatDontNeedAuth.includes(this.$route.name);
      return needAuth && !this.$store.state.authenticated;
    },
    isMuted() {
      return this.$store.state.videoPlayer.backgroundVideoMuted;
    }
  },
  methods: {
    openBroadcastModal() {
      this.$store.commit('userActivities/openBroadcastModal');
    },
    openLiveListeningIndex() {
      this.$store.commit('liveListeningRooms/openLiveListeningIndex');
    },
    getProfileRouteName() {
      switch (this.user.profile_type) {
        case 'UserProfile':
          return 'user_profiles.show';
        case 'ArtistProfile':
          return 'artist_profiles.show';
        case 'LabelProfile':
          return 'label_profiles.show';
      }
    },
    getProfileParams() {
      switch (this.user.profile_type) {
        case 'UserProfile':
          return { userProfileId: this.user.profile_id };
        case 'ArtistProfile':
          return { artistProfileId: this.user.profile_id };
        case 'LabelProfile':
          return { labelProfileId: this.user.profile_id };
      }
    },
    updateVideoUrl(url) {
      this.$store.commit("videoPlayer/setVideoBackgroundUrl", url);
    },
    ...mapMutations(['resetStore']),
    setSpeechRecognitionInstance() {
      /* eslint-disable no-undef */
      this.webSpeech = new webkitSpeechRecognition();
    },
    logout() {
      localStorage.removeItem('token');
      this.resetStore();
      this.$router.push('/login');
      this.webSpeech.stop();
    }
  },
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.hud-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;

  #live-listening-link {
    position: relative;
  }

  .contenthidden {
    display: none;
  }

  .contentwithbackground {
    margin: .5rem .5rem 0rem .5rem;
    background-color: rgba($atmo-purple--extra-dark, 0.3);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
  }
}

.hud-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background-color: #40365E;
  background-color: rgba($atmo-purple--extra-dark, 0.6);
  backdrop-filter: blur(5px);
  padding: 20px 40px;
  position: relative;
  z-index: $hud-header-z-index;

  .play-video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(255, 255, 255, 0.9);
    border-radius: 100%;
    width: 32px;
    height: 32px;
    margin-top: -2px;
    margin-right: 5px;

    .play-video-icon {
      height: .5rem;
      width: auto;
    }
  }

  &__button-group {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 32px;
    height: 32px;
    margin: 5px;

    &--wide {
      width: 46px;
      margin-right: -1px;
    }

    &--circular {
      border-radius: 50px;
      border: 2px solid white;
    }
  }
}

.hud-header-search {
  position: relative;
  margin-left: 20px;

  &__input {
    width: 162px;
    cursor: text;
  }

  &__hide-results-icon {
    margin: 5px 0 0 5px;

    img {
      height: 15px;
    }
  }

  &__results {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: -6px;
    margin-top: 6px;
    width: 159px;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    z-index: 100;
    box-shadow: 1px 0 0 0 rgba(64, 54, 93, 0.15), 0 1px 5px 0 $atmo-purple--extra-dark;
    border-radius: 5px;
    padding: 5px 0;
  }

  &__result {
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;

    &:hover {
      background-color: rgba($atmo-purple--light, 0.4);
    }
  }
}

.hud-row {
  // flex-grow: 1;
  height: 83.3vh;
  display: flex;
}

.hud-sidebar {
  min-width: 180px;
  display: flex;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 370px;
    padding: 0px 15px 0px 15px;
    width: 12rem; //Probably will need to adjust this
  }

  &__module {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    height: $hud-module-height; //Probably will need to adjust this
  }

  &__loading-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__scrollable-content {
    flex-grow: 1;
    margin: 10px 0;
    min-height: 14rem;
    // overflow: auto;
  }
}

.hud-sidebar-border-line {
  height: 100px;
  width: 5px;
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  background-color: rgba(213, 213, 213, 0.5);
}

.hud-main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
