<template>
  <section class="atmo-chat hud-sidebar__module">
    <div class="input-max-container">
      <!-- TODO - make this actually search friends -->
      <input class="atmo-input atmo-input--search atmo-chat__text-input" type="text" placeholder="Type Friend Name"
        v-model="searchQuery" @input="filterList">
      <img @click="showMaxChatModal()" style="cursor:pointer" src="@/assets/images/icons/max_library.png"
        alt="Show Max Library" class="mini-library__icon">
      <notification-dot dot-id="max-chat-dot" notification-type="Group Chat Request"
        style="position:absolute; top:-4px; right:-4px"></notification-dot>
    </div>
    <div v-if="currentView === 'friends'" class="hud-sidebar__scrollable-content">
      <perfect-scrollbar>
        <div v-for="friendship in filteredFriendList" :key="friendship.id" class="atmo-chat__friend"
          style="cursor:pointer" @click="setChatRoomAttributes($store.state.currentUserId, friendship.friend);">
          <!-- ng-repeat="friendship in userFriendships | filter:{state:'accepted'}" -->
          <img class="atmo-avatar atmo-avatar--extra-small atmo-avatar--no-border"
            :src="getFeaturedImage(friendship.friend.images, 'full')">
          <notification-dot :chat-user="friendship.friend" dot-size="small" dot-id="" notification-type="Chat Message"
            style="position:absolute; top:-1px; left:17px;"></notification-dot>
          <p class="atmo-chat__friend-name">
            {{ friendship.friend.name }}
          </p>
        </div>
      </perfect-scrollbar>
    </div>
    <div v-if="currentView === 'messages'" class="hud-sidebar__scrollable-content">
      <div v-if="$store.state.chatRooms.currentChatRoomMessages.length === 0" class="empty-messages">Empty Chat History
        :(</div>
      <perfect-scrollbar v-else>
        <div v-for="message in $store.state.chatRooms.currentChatRoomMessages" :key="message.id"
          :class="messageClassFor(message)">
          <div class="atmo-chat__message-content">
            <p class="atmo-chat__message-text" v-html="formatMessage(message.body)"></p>
            <div v-if="message.song_references.length > 0" class="atmo-chat__message-song">
              <img class="atmo-chat__message-song-icon" src="@/assets/images/icons/play.png">
              <a class="atmo-chat__message-song-name">
                {{ message.song_references[0].song.name }}
              </a>
            </div>
            <div v-if="message.chat_message_image" class="atmo-chat__message-image-wrap"
              @click="openChatImageModal(message.chat_message_image.image.url)">
              <img class="atmo-chat__message-image" :src="message.chat_message_image.image.url">
            </div>
            <p class="atmo-chat__message-timestamp">
              {{ getDate(message.created_at) }}
            </p>
          </div>
          <profile-link :profile-type="message.user.profile_type" :profile-id="message.user.profile_id">
            <div class="atmo-avatar atmo-avatar--extra-small atmo-avatar--no-border"
              :style="{ 'background-image': 'url(' + getFeaturedImage(message.user.images, 'thumb') + ')' }"
              title="message.user.name" />
          </profile-link>
        </div>
      </perfect-scrollbar>
    </div>
    <div v-if="currentView === 'messages'" class="atmo-chat__messages-footer">
      <form class="atmo-chat__form" @submit.prevent="saveMessage(newMessageBody)">
        <input v-model="newMessageBody" class="atmo-input atmo-input--square atmo-chat__text-input"
          placeholder="Type your message here" type="text">
        <emoji-picker @emoji="insert" :search="emojiSearch" class="emoji-selection-container">
          <div slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
            <button type="button" class="emoji-picker-trigger">☻</button>
          </div>
          <div slot="emoji-picker" slot-scope="{ emojis, insert }" class="input-emojis-container">
            <div>
              <perfect-scrollbar class="emojis-container">
                <div class="emoji-search-container">
                  <input class="emoji-search" type="text" v-model="emojiSearch" placeholder="Search for emojis">
                </div>
                <div v-for="(emojiGroup, category) in emojis" :key="category" class="emoji-category-container">
                  <p class="category-heading">{{ category }}</p>
                  <div>
                    <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)"
                      :title="emojiName">{{ emoji }}</span>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </emoji-picker>
      </form>
      <div class="mini-library__toggle-link" style="cursor:pointer" @click="viewFriends()">
        <a>Friends list</a>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import { sendMessage, setCallback, connectToAtmoChatChannel } from '@/cable-channels/atmoChat';
import EmojiPicker from 'vue-emoji-picker';
import { formatDateToDayTimeYear, findOtherUsersInRoom, getFeaturedImage } from '@/helpers/utilityFunctions';
import {
  callUserFriendshipsIndex,
  callUserProfilesShow,
  callUsersShow,
  callChatMessagesIndex,
  callChatRoomUsersCreate,
  callChatRoomsIndex,
  callChatRoomsCreate,
  callChatMessagesCreate
} from '@/helpers/axiosCalls';
import NotificationDot from '@/components/notifications/notification-dot';
import ProfileLink from '@/components/profile-link'

export default {
  components: {
    EmojiPicker,
    NotificationDot,
    ProfileLink
  },
  data: function () {
    return {
      currentUserProfile: {},
      currentUser: {},
      userFriendships: [],
      chatRoom: [],
      messages: [],
      allChatRooms: [],
      userChatRoomId: null,
      friendChatRoomId: null,
      newMessageBody: '',
      emojiSearch: '',
      searchQuery: ''
    }
  },
  computed: {
    currentView() {
      return this.$store.state.chatRooms.chatCurrentView;
    },
    acceptedFriendships() {
      return this.$store.state.chatRooms.chatUserFriendships.filter(friendship =>
        friendship.state === 'accepted'
      );
    },
    filteredFriendList() {
      return this.acceptedFriendships.filter(friendship =>
        friendship.friend.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  created() {
    this.getCurrentUser();
    this.getUserFriendships();
  },
  methods: {
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    formatMessage(message) {
      // Use a regular expression to find URLs in the message and replace them with clickable links
      const urlPattern = /https?:\/\/[^\s]+/g;
      return message.replace(urlPattern, (url) => {
        return `<a class="chat-link" href="${url}" target="_blank">${url}</a>`;
      });
    },
    getDate(date) {
      return formatDateToDayTimeYear(date);
    },
    openChatImageModal(url) {
      this.$store.commit('chatRooms/setChatImageModalUrl', url)
      this.$store.commit('chatRooms/showChatImageModal');
    },
    filterList() {
      // This method is bound to the input event and updates the filtered list
      // based on the search query in real-time.
    },
    showMaxChatModal() {
      this.$store.commit('chatRooms/showMaxChatModal');
    },
    insert(emoji) {
      this.newMessageBody += emoji;
    },
    messageClassFor(message) {
      var classes = ['atmo-chat__message'];
      if (message.user_id === this.currentUser.profile_id) {
        classes.push('atmo-chat__message--current-user');
      }

      return classes.join(' ');
    },
    getCurrentUserProfile(profileId) {
      callUserProfilesShow(profileId)
        .then((response) => {
          this.currentUserProfile = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCurrentUser() {
      callUsersShow(this.$store.state.currentUserId)
        .then((response) => {
          this.currentUser = response;
          this.getCurrentUserProfile(this.currentUser.profile_id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getUserFriendships() {
      callUserFriendshipsIndex({ user_id: this.$store.state.currentUserId })
        .then((response) => {
          this.userFriendships = response;
          this.$store.commit('chatRooms/setChatUserFriendships', this.userFriendships);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getRoomMessages(roomId) {
      callChatMessagesIndex(roomId)
        .then((response) => {
          this.messages = response;
          this.$store.commit('chatRooms/setChatRoomMessages', this.messages);
          Vue.nextTick(() => {
            this.scrollToBottom();
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    scrollToBottom() {
      console.log("TODO: FIX SCROLLING TO BOTTOM");
      // const scrollContainer = this.$el.querySelector('.messages-container');
      // scrollContainer.scrollTop = scrollContainer.scrollHeight;
    },
    retrieveRoomMessages(chatRoomId) {
      setCallback(() => {
        this.getRoomMessages(chatRoomId);
      });
      connectToAtmoChatChannel(chatRoomId);
      this.$store.commit('chatRooms/changeChatView', "messages");

      callChatMessagesIndex(chatRoomId)
        .then((response) => {
          this.messages = response;
          this.$store.commit('chatRooms/setChatRoomMessages', this.messages);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveChatUser(roomId, userId) {
      callChatRoomUsersCreate(roomId, {
        chat_room_id: roomId,
        user_id: userId,
        is_admin: true
      })
        .then(() => {
          console.log(`SAVING USER ${userId} TO ROOM ${roomId} SUCCESSFUL`);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    createRoom() {
      callChatRoomsCreate({
        name: this.userChatRoomId + "_" + this.friendChatRoomId,
        room_users_id: this.userChatRoomId + "_" + this.friendChatRoomId,
        room_type: "Friend",
        creator_id: this.userChatRoomId,
      })
        .then((res) => {
          this.$store.commit("chatRooms/setCurrentChatRoom", res);
          let userIdArr = [this.userChatRoomId, this.friendChatRoomId];

          userIdArr.forEach(async (id) => {
            await this.saveChatUser(this.$store.state.chatRooms.currentChatRoom.id, id);
          });

          this.retrieveRoomMessages(this.$store.state.chatRooms.currentChatRoom.id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setChatRoomAttributes(userChatRoomId, friendChatRoom) {
      this.userChatRoomId = userChatRoomId;
      this.friendChatRoomId = friendChatRoom.id;
      this.$store.commit("chatRooms/setCurrentChatTitleData", {
          name: friendChatRoom.name,
          roomType: "friend",
          profileType: friendChatRoom.profile_type,
          profileId: friendChatRoom.profile_id
      });

      this.groupChatRoomSelected = false;
      callChatRoomsIndex({
        user_id: this.userChatRoomId,
        friend_id: this.friendChatRoomId
      })
        .then((response) => {
          if (Object.keys(response).length === 0) {
            this.createRoom(); // If no room exists, create one
          } else {
            this.$store.commit("chatRooms/setCurrentChatRoom", response);
            this.retrieveRoomMessages(this.$store.state.chatRooms.currentChatRoom.id);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveMessage(messageBody) {
      const currentChatRoom = this.$store.state.chatRooms.currentChatRoom;

      if (!currentChatRoom || !currentChatRoom.id) {
        console.error('No chat room selected or currentChatRoom.id is undefined');
        return;
      }

      callChatMessagesCreate(currentChatRoom.id, {
        chat_room_id: currentChatRoom.id,
        user_id: this.$store.state.currentUserId,
        body: messageBody
      })
        .then(() => {
          sendMessage(messageBody, currentChatRoom.id, this.$store.state.currentUserId);
          this.newMessageBody = '';
          this.getRoomMessages(currentChatRoom.id);
          setCallback(() => {
            this.getRoomMessages(currentChatRoom.id);
          });

          const otherUsersInRoom = findOtherUsersInRoom(currentChatRoom, this.$store);
          otherUsersInRoom.forEach(user => {
            this.$store.dispatch('notifications/createNotification', {
              userId: user.user_id,
              payload: {
                sender_id: this.$store.state.currentUserId,
                receiver_id: user.user_id,
                notification_type_name: 'Chat Message',
                status: 'unread',
                notifiable_type: 'ChatRoom',
                notifiable_id: currentChatRoom.id
              }
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    viewFriends() {
      this.$store.commit('chatRooms/changeChatView', "friends");
    },
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-chat {
  margin-bottom: 1rem;

  //https://github.com/mercs600/vue2-perfect-scrollbar
  .ps {
    max-height: $hud-module-height;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: white;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: white;
  }

  &__loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__loading-spinner {
    width: 27px;
    height: 27px;
    margin-bottom: 10px;
  }

  .empty-messages {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
  }

  &__friend {
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: color 0.2s ease-in-out;
    margin: 5px 0;

    &:hover {
      color: $atmo-purple--dark;
    }
  }

  &__friend-name {
    padding: 3px;
    font-weight: 400;
  }

  &__message {
    display: flex;
    padding: 7px 0;

    &--current-user {
      flex-direction: row-reverse;

      .atmo-chat__message-content {
        padding: 7px 6px 3px;
        background: rgba($atmo-purple--dark, 0.39);
        border-radius: 2px;
        margin-left: 5px;
      }

      .chat-link {
        color: $atmo-blue--medium;
        max-width: 15ch;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .atmo-chat__message-timestamp {
        align-self: flex-end;
        color: white;
      }
    }
  }

  &__message-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .chat-link {
      color: $atmo-blue--medium;
      max-width: 15ch;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__message-text {
    color: white;
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 5px;
    max-width: 5rem;
  }

  &__message-song {
    display: flex;
    cursor: pointer;
  }

  &__message-song-icon {
    height: 15px;
    width: 15px;
  }

  &__message-song-name {
    color: white;
    cursor: pointer;
    font-style: italic;
    font-size: 0.8rem;
    font-weight: 400;
    transition: color 0.2s ease-in-out;
    margin-bottom: 5px;

    &:hover {
      color: $atmo-purple--dark;
    }
  }

  &__message-image-wrap {
    display: flex;
    cursor: pointer;
    max-width: 8rem;
    margin-bottom: .5rem;
  }

  &__message-image {
    object-fit: cover;
    width: 100%;
    border-radius: 2px;
    // height: 15rem;
    // width: 15rem;
  }

  &__message-timestamp {
    color: $atmo-purple--dark;
    font-size: 0.6rem;
    font-weight: 400;
  }

  &__messages-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__form {
    width: 100%;
    margin: 5px 0;
    z-index: 1;
    position: relative;
  }

  .input-max-container {
    position: relative;
    display: flex;

    .atmo-input--search {
      max-width: 8rem;
    }

    img {
      width: 28px;
      height: 28px;
      margin-left: 5px;
    }
  }

  .emoji-selection-container {
    width: 10rem;
    position: absolute;
    right: .2rem;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }

  .emoji-picker-trigger {
    position: absolute;
    right: 0rem;
    top: 0rem;
    z-index: 1;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    font-size: 1.3rem;
  }

  .emoji-search-container {
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    padding: .5rem .5rem .5rem .5rem;
    border-radius: 5px 5px 0px 0px;
  }

  .emoji-search {
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, .4);
    color: white;
    width: 90%;
    position: relative;
    background: url('@/assets/images/icons/search.png') no-repeat scroll 7px 7px;
    background-size: .5rem;
    background-position: 10px 7px;
    font-size: .7rem;
    padding: 5px 5px 5px 23px;
  }

  .input-emojis-container {
    height: 10rem;
    z-index: 1;
  }

  .emojis-container {
    max-width: 10rem;
    border-radius: 0px 0px 5px 5px;
  }

  .emoji-category-container {
    z-index: 1;
    padding: .5rem;
    max-width: 10rem;
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);

    .category-heading {
      font-weight: 600;
      font-size: .8rem;
      margin-bottom: .5rem;
      text-transform: uppercase;
    }

    div {
      max-width: 10rem;
      display: flex;
      gap: 2px;
      flex-wrap: wrap;
      cursor: pointer;
    }
  }

  .atmo-chat__friend {
    display: flex;
    align-items: center;
  }

  .atmo-avatar {
    width: 1.4rem;
    height: 1.4rem;
    min-width: unset;
    border: none;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 5px;

    &--small {
      min-width: unset;
    }
  }
}
</style>
@/cable-channels/atmoChat