import Axios from "axios";

export default {
    namespaced: true,
    state: {
        debug: false,
        genrePlayCounts: [],
        artistPlayCounts: [],
        moodPlayCounts: [],
        lyricsThemePlayCounts: [],
        occasionPlayCounts: [],
        songTonalityPlayCounts: [],
        tempoPlayCounts: [],
        timeSignaturePlayCounts: [],
        songFormPlayCounts: [],
        yearPlayCounts: [],
        instrumentationPlayCounts: [],
        voxQualityPlayCounts: [],
        albumPlayCounts: [],
        genreInLibrary: [],
        artistInLibrary: [],
        moodInLibrary: [],
        lyricsThemeInLibrary: [],
        occasionInLibrary: [],
        songTonalityInLibrary: [],
        tempoInLibrary: [],
        timeSignatureInLibrary: [],
        songFormInLibrary: [],
        yearInLibrary: [],
        instrumentationInLibrary: [],
        albumInLibrary: [],
        voxQualityInLibrary: [],
        allMusicProfileGroups: [],
        musicProfileModalOpen: false,
    },
    mutations: {
        setMusicProfileGroups(state, { metricName, records }) {
            state[metricName] = records;
        },
        addMusicProfileGroup(state, group) {
            state.allMusicProfileGroups.push(group);
        },
        openMusicProfileModal(state) {
            state.musicProfileModalOpen = true;
        },
        closeMusicProfileModal(state) {
            state.musicProfileModalOpen = false;
        },
    },
    actions: {
        buildUserMusicProfile({ dispatch, rootState }) {
            Axios.get(`/api/v1/user_music_profiles/${rootState.currentUserId}`)
                .then(response => {
                    const userMusicProfileRecords = response.data;
                    dispatch("analyzeUserMusicProfileRecords", userMusicProfileRecords);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        analyzeUserMusicProfileRecords({ commit }, userMusicProfileRecords) {
            const profileMetrics = [
                { metric_name: "genrePlayCounts", attribute_name: "genre_id" },
                { metric_name: "artistPlayCounts", attribute_name: "artist_profile_id" },
                { metric_name: "moodPlayCounts", attribute_name: "mood_id" },
                { metric_name: "lyricsThemePlayCounts", attribute_name: "lyrics_theme_id" },
                { metric_name: "occasionPlayCounts", attribute_name: "occasion_id" },
                { metric_name: "songTonalityPlayCounts", attribute_name: "song_tonality_id" },
                { metric_name: "tempoPlayCounts", attribute_name: "tempo" },
                { metric_name: "timeSignaturePlayCounts", attribute_name: "time_signature_id" },
                { metric_name: "songFormPlayCounts", attribute_name: "song_form_id" },
                { metric_name: "yearPlayCounts", attribute_name: "year_id" },
                { metric_name: "instrumentationPlayCounts", attribute_name: "instrumentation_id" },
                { metric_name: "voxQualityPlayCounts", attribute_name: "vox_quality_id" },
                { metric_name: "albumPlayCounts", attribute_name: "album_id" },
                { metric_name: "genreInLibrary", attribute_name: "genre_id" },
                { metric_name: "artistInLibrary", attribute_name: "artist_profile_id" },
                { metric_name: "moodInLibrary", attribute_name: "mood_id" },
                { metric_name: "lyricsThemeInLibrary", attribute_name: "lyrics_theme_id" },
                { metric_name: "occasionInLibrary", attribute_name: "occasion_id" },
                { metric_name: "songTonalityInLibrary", attribute_name: "song_tonality_id" },
                { metric_name: "tempoInLibrary", attribute_name: "tempo" },
                { metric_name: "timeSignatureInLibrary", attribute_name: "time_signature_id" },
                { metric_name: "songFormInLibrary", attribute_name: "song_form_id" },
                { metric_name: "yearInLibrary", attribute_name: "year_id" },
                { metric_name: "instrumentationInLibrary", attribute_name: "instrumentation_id" },
                { metric_name: "albumInLibrary", attribute_name: "album_id" },
                { metric_name: "voxQualityInLibrary", attribute_name: "vox_quality_id" },
            ];

            profileMetrics.forEach(metric => {
                const filteredRecords = userMusicProfileRecords.filter(record => record.metric_type === metric.metric_name);
                commit("setMusicProfileGroups", { metricName: metric.metric_name, records: filteredRecords });
                commit("addMusicProfileGroup", filteredRecords);
            });
        }
    },
    getters: {
        getGenrePlayCounts: state => state.genrePlayCounts,
        getArtistPlayCounts: state => state.artistPlayCounts,
        getMoodPlayCounts: state => state.moodPlayCounts,
        getLyricsThemePlayCounts: state => state.lyricsThemePlayCounts,
        getOccasionPlayCounts: state => state.occasionPlayCounts,
        getSongTonalityPlayCounts: state => state.songTonalityPlayCounts,
        getTempoPlayCounts: state => state.tempoPlayCounts,
        getTimeSignaturePlayCounts: state => state.timeSignaturePlayCounts,
        getSongFormPlayCounts: state => state.songFormPlayCounts,
        getYearPlayCounts: state => state.yearPlayCounts,
        getInstrumentationPlayCounts: state => state.instrumentationPlayCounts,
        getVoxQualityPlayCounts: state => state.voxQualityPlayCounts,
        getAlbumPlayCounts: state => state.albumPlayCounts,
        getGenreInLibrary: state => state.genreInLibrary,
        getArtistInLibrary: state => state.artistInLibrary,
        getMoodInLibrary: state => state.moodInLibrary,
        getLyricsThemeInLibrary: state => state.lyricsThemeInLibrary,
        getOccasionInLibrary: state => state.occasionInLibrary,
        getSongTonalityInLibrary: state => state.songTonalityInLibrary,
        getTempoInLibrary: state => state.tempoInLibrary,
        getTimeSignatureInLibrary: state => state.timeSignatureInLibrary,
        getSongFormInLibrary: state => state.songFormInLibrary,
        getYearInLibrary: state => state.yearInLibrary,
        getInstrumentationInLibrary: state => state.instrumentationInLibrary,
        getAlbumInLibrary: state => state.albumInLibrary,
        getVoxQualityInLibrary: state => state.voxQualityInLibrary,
        getAllMusicProfileGroups: state => state.allMusicProfileGroups
    }
};
