import Axios from 'axios';

//PASSING IN user_id PARAM WILL SHOW UserSongPlayCount
export function callSongPlayCountsShow(songId, queryParams = {}) {
    return Axios.get(`/api/v1/songs/${songId}/song_play_count`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

//PASSING IN user_id PARAM WILL CREATE UserSongPlayCount
export function callSongPlayCountsCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/song_play_counts`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

//PASSING IN user_id PARAM WILL UPDATE UserSongPlayCount
export function callSongPlayCountsUpdate(songId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/songs/${songId}/song_play_count`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}