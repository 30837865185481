import {
  callAtmoRadioStationsIndex,
  callAtmoRadioStationsShow,
  callAtmoRadioStationsCreate,
  callAtmoRadioStationsSearch
} from '@/helpers/axiosCalls';

export default {
  namespaced: true,
  state: {
    debug: true,
    atmoRadioStationData: {},
    userRadioStations: [],
    atmoRadioSearchResultsOpen: false,
    searchResults: {},
  },
  mutations: {
    setAtmoRadioStationData(state, data) {
      if (state.debug) console.log('Mutation: setAtmoRadioStationData - data:', data);
      state.atmoRadioStationData = data;
    },
    setUserRadioStations(state, stations) {
      if (state.debug) console.log('Mutation: setUserRadioStations - stations:', stations);
      state.userRadioStations = stations;
    },
    toggleSearchResultsModal(state) {
      if (state.debug) console.log('Mutation: toggleSearchResultsModal - current state:', state.atmoRadioSearchResultsOpen);
      state.atmoRadioSearchResultsOpen = !state.atmoRadioSearchResultsOpen;
      if (state.debug) console.log('Mutation: toggleSearchResultsModal - new state:', state.atmoRadioSearchResultsOpen);
    },
    setSearchResults(state, results) {
      if (state.debug) console.log('Mutation: setSearchResults - data:', results);
      state.searchResults = results;
    },
  },
  actions: {
    async fetchUserRadioStations({ commit, state, rootState }) {
      if (state.debug) console.log('Action: fetchUserRadioStations - rootState.currentUserId:', rootState.currentUserId);
      try {
        const stations = await callAtmoRadioStationsIndex(rootState.currentUserId, { length: 5 });
        if (state.debug) console.log('Action: fetchUserRadioStations - stations fetched:', stations);
        commit('setUserRadioStations', stations);
      } catch (error) {
        console.error('Action: fetchUserRadioStations - Failed to fetch user radio stations:', error);
      }
    },
    async fetchUserRadioStationSongs({ commit, state }, { station_id, station_type, station_query, resource_id, user_id, length }) {
      if (state.debug) console.log(`Action: fetchUserRadioStationSongs - user_id: ${user_id}, station_type: ${station_type}, station_query: ${station_query}`);
      try {
        const stationData = await callAtmoRadioStationsShow(user_id, station_id, {
          station_type: station_type,
          station_query: station_query,
          resource_id: resource_id,
          length: length
        });
    
        if (state.debug) console.log('Action: fetchUserRadioStationSongs - stationData fetched:', stationData);
        commit('setAtmoRadioStationData', stationData);
      } catch (error) {
        console.error(`Action: fetchUserRadioStationSongs - Failed to fetch songs for radio station with ID ${station_id} or params: station_type=${station_type}, station_query=${station_query}`, error);
      }
    },
    async createUserRadioStation({ dispatch, state, rootState }, { type, query }) {
      if (state.debug) console.log(`Action: createUserRadioStation - Creating station with type: ${type} and query: ${query}`);
      try {
        const response = await callAtmoRadioStationsCreate(rootState.currentUserId, {
          name: `${type} - ${query}`,
          station_type: type,
          station_query: query,
        });
        if (state.debug) console.log('Action: createUserRadioStation - station created:', response);
        await dispatch('fetchUserRadioStationSongs', response.id);
      } catch (error) {
        console.error('Action: createUserRadioStation - Failed to create radio station:', error);
      }
    },
    async fetchSearchResults({ commit, state, rootState }, query) {
      if (state.debug) console.log(`Action: fetchSearchResults - Searching with query: ${query}`);
      try {
        const response = await callAtmoRadioStationsSearch(rootState.currentUserId, query);
        if (state.debug) console.log('Action: fetchSearchResults - search results:', response);
        commit('setSearchResults', response);
      } catch (error) {
        console.error(`Action: fetchSearchResults - Failed to fetch search results for query: ${query}`, error);
      }
    }
  },
  getters: {
    stationDataIsEmpty: (state) => {
      const isEmpty = !state.atmoRadioStationData.station_songs || state.atmoRadioStationData.station_songs.length === 0;
      if (state.debug) console.log('Getter: stationDataIsEmpty - result:', isEmpty);
      return isEmpty;
    },
    stationData: (state) => {
      if (state.debug) console.log('Getter: stationData - data:', state.atmoRadioStationData);
      return state.atmoRadioStationData;
    },
    userRadioStations: (state) => {
      if (state.debug) console.log('Getter: userRadioStations - data:', state.userRadioStations);
      return state.userRadioStations;
    },
    stationSongs: (state) => {
      const songs = state.atmoRadioStationData.station_songs || [];
      if (state.debug) console.log('Getter: stationSongs - data:', songs);
      return songs;
    },
    currentSong: (state) => {
      const song = state.atmoRadioStationData.station_songs?.[0] || {};
      if (state.debug) console.log('Getter: currentSong - data:', song);
      return song;
    },
    atmoRadioSearchResultsOpen: (state) => {
      const atmoRadioSearchResultsOpen = state.atmoRadioSearchResultsOpen;
      if (state.debug) console.log('Getter: atmoRadioSearchResultsOpen - data:', atmoRadioSearchResultsOpen);
      return atmoRadioSearchResultsOpen;
    },
    searchResults: (state) => {
      if (state.debug) console.log('Getter: searchResults - data:', state.searchResults);
      return state.searchResults;
    }
  }
};
