<template>
  <b-modal id="music-profile" ref="musicProfileRef" v-model="$store.state.musicProfileModalOpen" title="Music Profile"
    modal-class="atmo-modal music-profile" size="lg" hide-header hide-footer centered>
    <div class="header-container">
      <div class="header-container__heading-wrap">
        <h2 class="atmo-page-header">
          Music Profile
        </h2>
      </div>
    </div>
    <img class="music-profile__close" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Profile"
      @click="$refs.musicProfileRef.hide()">

    <div class="music-profile__data-container">
      <div class="data-container__lib-habits-plays-container">
        <div class="lib-habits-plays-container__lib-comp-container">
          <div class="chart-header">Library Composition</div>
          <template>
            <div id="lib-composition-chart">
              <apexchart type="pie" width="380" :options="libCompositionOptions" :series="libCompositionSeries" />
            </div>
          </template>
        </div>
        <div class="lib-habits-plays-container__listening-habits-container">
          <!-- Genre play counts
          Artist play counts
          Mood play counts
          Lyrics theme play counts
          Occassion play counts -->
          <div class="chart-header">Listening Habits</div>
          <template>
            <div id="listening-habits-chart">
              <apexchart type="donut" width="380" :options="listeningHabitsOptions" :series="listeningHabitsSeries" />
            </div>
          </template>
        </div>
        <div class="lib-habits-plays-container__attribute-plays-container">
          <div class="chart-header">Trend Data For Attribute Plays</div>
          <template>
            <div id="attribute-plays-chart">
              <apexchart type="area" :options="attributePlaysOptions" :series="attributePlaysSeries">
              </apexchart>
            </div>
          </template>
        </div>
      </div>
      <div class="data-container__friends-misc-stats-container">
        <div class="friends-misc-stats-container__friends-music-container">
          <div class="chart-header">Friends' Music Tastes</div>
          <template>
            <div id="friends-tastes-chart">
              <apexchart type="bubble" height="380" width="700" :options="friendsTastesOptions"
                :series="friendsTastesSeries">
              </apexchart>
            </div>
          </template>
        </div>
        <div class="friends-misc-stats-container__misc-stats-container">
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Most played artists</div>
            <ul class="misc-stats-wrap__list">
              <li v-for="index in 3" :key="index">{{ generateRandomString(artistNameArray) }} -
                {{ generateRandomNumberFromRange(1, 1000) }} plays, {{ generateRandomNumberFromRange(1, 100) }} songs,
                ({{ generateRandomNumberFromRange(1, 100) }}% of library)</li>
            </ul>
          </div>
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Recently followed artists</div>
            <ul class="misc-stats-wrap__list">
              <li v-for="index in 3" :key="index">{{ generateRandomString(artistNameArray) }}</li>
            </ul>
          </div>
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Suggested Songs</div>
            <ul class="misc-stats-wrap__list">
              <li v-for="index in 3" :key="index">{{ generateRandomString(songTitleArray) }} by
                {{ generateRandomString(artistNameArray) }}</li>
            </ul>
          </div>
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Suggested Artists</div>
            <ul class="misc-stats-wrap__list">
              <li v-for="index in 3" :key="index">{{ generateRandomString(artistNameArray) }}</li>
            </ul>
          </div>
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Recently shared songs</div>
            <ul class="misc-stats-wrap__list">
              <li v-for="index in 3" :key="index">{{ generateRandomString(songTitleArray) }} by
                {{ generateRandomString(artistNameArray) }}</li>
            </ul>
          </div>
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Friends with most similar music tastes</div>
            <ul class="misc-stats-wrap__list">
              <li v-for="index in 3" :key="index">{{ generateRandomString(friendNameArray) }}</li>
            </ul>
          </div>
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Friends with least similar music tastes</div>
            <ul class="misc-stats-wrap__list">
              <li v-for="index in 3" :key="index">{{ generateRandomString(friendNameArray) }}</li>
            </ul>
          </div>
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Most played songs</div>
            <ul class="misc-stats-wrap__list">
              <li v-for="index in 3" :key="index">{{ generateRandomString(songTitleArray) }} by
                {{ generateRandomString(artistNameArray) }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- <div>
      <template>
        <div id="chart">
          <apexchart type="pie" :options="libCompositionOptions" :series="series" />
        </div>
      </template>
      <div v-for="group in $store.state.allMusicProfileGroups" v-bind:key="group.id">
        <div v-for="metric in group" v-bind:key="metric.id">
          <p>metric type: {{metric.metric_type}} | percentage: {{metric.percentage}} | number of plays/songs: {{metric.number_of_plays || metric.number_of_songs}}</p>
        </div>
      </div>
    </div> -->
  </b-modal>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import devMockData from '@/devUtils/mockData.js';
//https://github.com/apexcharts/apexcharts.js/tree/main/samples/vue

export default {
  components: {
    apexchart: VueApexCharts
  },

  data() {
    return {
      isOpen: true,
      songTitleArray: devMockData.songTitleArray,
      artistNameArray: devMockData.artistNameArray,
      artistMerchArray: devMockData.artistMerchArray,
      friendNameArray: devMockData.friendNameArray,
      libCompositionOptions: {
        // theme: {
        //   monochrome: {
        //     enabled: true,
        //     color: '#44AEDC',
        //     shadeTo: 'light',
        //     shadeIntensity: 0.65
        //   }
        // },
        legend: {
          // show: true,
          show: false,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0
          },
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        fill: {
          // type: 'gradient',
          colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
        },
        colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
        // dataLabels: { style: { colors: ['#F44336', '#E91E63', '#9C27B0'] } },
        markers: { colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'] },
        stroke: {
          width: 0
        },
        chart: {
          type: "donut"
        },
        labels: ["Rock", "Pop", "Country"]
      },
      libCompositionSeries: [30, 40, 35],
      listeningHabitsOptions: {
        // theme: {
        //   monochrome: {
        //     enabled: true,
        //     color: '#40365D',
        //     shadeTo: 'light',
        //     shadeIntensity: 0.65
        //   }
        // },
        legend: {
          // show: true,
          show: false,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0
          },
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          },
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
          }
        },
        chart: {
          width: 380,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270
          }
        },
        // dataLabels: {
        //   enabled: false
        // },
        fill: {
          // type: 'gradient',
          colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
        },
        colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
        // dataLabels: { style: { colors: ['#F44336', '#E91E63', '#9C27B0'] } },
        markers: { colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'] },
        stroke: {
          width: 0
        },
        labels: ["Rock", "Pop", "Country", "Jazz", "Blues"],
        // title: {
        //   text: 'Gradient Donut with custom Start-angle'
        // },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      listeningHabitsSeries: [44, 55, 41, 17, 15],
      attributePlaysSeries: [{
        name: "Jazz Plays",
        data: devMockData.areaChartSeries.monthDataSeries1.prices,
      }],
      attributePlaysOptions: {
        theme: {
          monochrome: {
            enabled: true,
            color: '#7feb9b',
            shadeTo: 'light',
            shadeIntensity: 0.65
          }
        },
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        // fill: {
        //   type: 'gradient',
        //   colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
        // },
        // colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
        // // dataLabels: { style: { colors: ['#F44336', '#E91E63', '#9C27B0'] } },
        // markers: { colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'] },
        stroke: {
          curve: 'straight'
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: false,
          theme: 'dark',
          // style: {
          //   fontSize: '12px',
          //   fontFamily: undefined,
          // },
        },
        // title: {
        //   text: 'Fundamental Analysis of Stocks',
        //   align: 'left'
        // },
        // subtitle: {
        //   text: 'Price Movements',
        //   align: 'left'
        // },
        labels: devMockData.areaChartSeries.monthDataSeries1.dates,
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          opposite: true
        },
        // legend: {
        //   horizontalAlign: 'left',
        //   labels: {
        //     colors: ['#fff'],
        //     useSeriesColors: false
        //   },
        //   markers: {
        //     width: 12,
        //     height: 12,
        //     strokeWidth: 0,
        //     strokeColor: '#fff',
        //     fillColors: '#fff',
        //     radius: 12,
        //     customHTML: undefined,
        //     onClick: undefined,
        //     offsetX: 0,
        //     offsetY: 0
        //   },
        // }
      },
      friendsTastesSeries: [{
        name: 'Friend Name',
        data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
          min: 10,
          max: 60
        })
      },
      {
        name: 'Friend Name',
        data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
          min: 10,
          max: 60
        })
      },
      {
        name: 'Friend Name',
        data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
          min: 10,
          max: 60
        })
      },
      {
        name: 'Friend Name',
        data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
          min: 10,
          max: 60
        })
      }],
      friendsTastesOptions: {
        // theme: {
        //   monochrome: {
        //     enabled: true,
        //     color: '#D952A7',
        //     shadeTo: 'light',
        //     shadeIntensity: 0.65
        //   }
        // },
        legend: {
          show: false,
        },
        chart: {
          height: 350,
          type: 'bubble',
        },
        dataLabels: {
          enabled: false
        },
        // fill: {
        //   // type: 'gradient',
        //   colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
        // },
        // stroke: {
        //   width: 0
        // },
        // title: {
        //   text: 'Simple Bubble Chart'
        // },
        colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
        fill: { colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'] },
        // dataLabels: { style: { colors: ['#F44336', '#E91E63', '#9C27B0'] } },
        markers: { colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'] },
        xaxis: {
          tickAmount: 12,
          type: 'category',
        },
        yaxis: {
          max: 70
        },
        tooltip: {
          theme: 'dark',
        },
      },
    };
  },

  created() { },

  methods: {
    hideModal() {
      this.$store.commit("hideModal");
    },
    onEnter: function () {
      console.log("OPEN");
    },
    generateData(baseval, count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
        var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

        series.push([x, y, z]);
        baseval += 86400000;
        i++;
      }
      return series;
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

#music-profile {
  .modal-content {
    background-color: rgba(0, 0, 0, .2);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    border: none;
    width: 100vw;
    height: 100vh;
  }
}

.music-profile {
  .chart-header {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .apexcharts-legend-text {
    color: white !important;
  }

  .apexcharts-text {
    fill: white !important;
  }

  .apexcharts-menu-icon {
    color: white;

    svg {
      fill: white;
    }

    svg:hover {
      fill: $atmo-purple--dark;
    }
  }

  .apexcharts-menu {
    background: $atmo-purple--dark;
    border: none;
  }

  .apexcharts-menu-item:hover {
    background: $atmo-purple--medium;
  }

  .modal-lg.modal-dialog {
    max-width: 100vw;
    margin: 0;
  }

  .header-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .header-container__heading-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    padding: .5rem 2rem 1.5rem 2rem;
    border-radius: 0px 0px 20px 20px;

    .atmo-page-header {
      margin: 0;
    }
  }

  .music-profile__close {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 3rem;
    height: 3rem;
    padding: 10px;
    cursor: pointer;
  }

  .music-profile__data-container {
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 1rem 2rem 2rem 2rem;
    // border: 1px solid white;
  }

  .data-container__lib-habits-plays-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .data-container__friends-misc-stats-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .friends-misc-stats-container__misc-stats-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }

  .misc-stats-wrap__title-wrap {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .misc-stats-wrap__list {
    list-style-type: disc;
    padding-left: 1rem;

    li {
      margin-bottom: .4rem;
    }
  }
}
</style>
