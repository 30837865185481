<template>
  <div>
    <div class="atmo-library__select-view">
      <div class="select-view__option" @click="backToLibrary()">Albums</div>
      <div class="select-view__option" @click="onSongsClick()">Songs</div>
    </div>
    <div class="atmo-library__body">
      <!-- Default View -->
      <section v-if="currentView === 'default'" class="atmo-library__main-column">
        <perfect-scrollbar class="main-column__scroll-container">
          <atmo-album-songs v-for="album in filteredItems" :key="album.id" :album="album" @on-album-click="onAlbumClick"
            :component-location="componentLocation" />
        </perfect-scrollbar>
      </section>

      <!-- Songs View -->
      <section v-if="currentView == 'songs'" class="atmo-library__main-column">
        <div v-if="songs == null">
          Loading…
        </div>
        <atmo-library-songs v-else :songs="filteredItems" :component-location="componentLocation" :scroll-enabled="true" />
      </section>

      <!-- Album View -->
      <section v-if="currentView == 'album'" class="atmo-library__main-column">
        <main-section-header :back-to-library="backToLibrary">
          {{ currentActiveObject.name }}
        </main-section-header>
        <div v-if="songs == null">
          Loading…
        </div>
        <atmo-library-songs v-else :songs="filteredItems" :component-location="componentLocation" />
      </section>

      <!-- Playlist View -->
      <section v-if="currentView == 'playlist'" class="atmo-library__main-column">
        <main-section-header :back-to-library="backToLibrary">
          {{ currentActiveObject.name }}
          <div slot="actions">
            <button class="atmo-icon-button" @click="deleteCurrentPlaylist">
              <img class="atmo-library__main-section-header-action-icon" src="@/assets/images/icons/delete.png"
                alt="delete playlist" title="delete playlist">
            </button>
          </div>
        </main-section-header>
        <playlist-collaborators :playlist="currentActiveObject" @update-playlists="handleUpdatePlaylists" />
        <div v-if="songs == null">
          Loading…
        </div>
        <atmo-library-songs v-else :songs="filteredItems" :component-location="componentLocation" />
      </section>

      <aside class="atmo-library__sidebar">
        <!-- TODO: make this search do something -->
        <input type="text" placeholder="Find album or song" class="atmo-input atmo-input--search"
          v-model="searchAlbumSongQuery">
        <atmo-playlists :playlists="playlists" @on-playlist-click="onPlaylistClick" />
        <atmo-lyrics />
      </aside>
    </div>
  </div>
</template>
  
<script>
import AtmoAlbumSongs from '@/modals/atmo-library/atmo-album-songs';
import AtmoLibrarySongs from '@/components/atmo-library/library-songs'
import AtmoPlaylists from '@/modals/atmo-library/atmo-playlists';
import AtmoLyrics from '@/modals/atmo-library/atmo-lyrics';
import MainSectionHeader from '@/modals/atmo-library/main-section-header';
import PlaylistCollaborators from '@/modals/atmo-library/playlist-collaborators';
import { callAtmoLibrariesIndex, callPlaylistsIndex, callSongReferencesIndex } from '@/helpers/axiosCalls';
import Axios from 'axios';

export default {
  props: {
    componentLocation: {
      type: String,
      default: null
    }
  },
  components: {
    AtmoAlbumSongs,
    AtmoPlaylists,
    AtmoLyrics,
    MainSectionHeader,
    PlaylistCollaborators,
    AtmoLibrarySongs,
  },
  data() {
    return {
      currentView: 'default',
      currentActiveObject: null,
      albums: [],
      playlists: [],
      songs: [],
      isOpen: true,
      searchAlbumSongQuery: '',
      allSongs: []
    }
  },

  computed: {
    filteredItems() {
      if (this.currentView == 'default') {
        return this.albums.filter(album =>
          album.name.toLowerCase().includes(this.searchAlbumSongQuery.toLowerCase())
        );
      } else if (this.currentView == 'songs') {
        return this.allSongs.filter(song =>
          song.name.toLowerCase().includes(this.searchAlbumSongQuery.toLowerCase())
        );
      } else if (this.currentView == 'playlist') {
        return this.songs.filter(song =>
          song.name.toLowerCase().includes(this.searchAlbumSongQuery.toLowerCase())
        );
      } else if (this.currentView == 'album') {
        return this.songs.filter(song =>
          song.name.toLowerCase().includes(this.searchAlbumSongQuery.toLowerCase())
        );
      } else {
        return null;
      }
    },
  },
  created() {
    this.getAlbums();
    this.getPlaylists();
  },
  methods: {
    handleUpdatePlaylists() {
      this.getPlaylists();
    },
    getAlbums() {
      if (this.$store.state.currentUserProfileId) {
        callAtmoLibrariesIndex(this.$store.state.currentUserProfileId)
          .then(response => {
            this.albums = response;
          });
      }
    },
    getPlaylists() {
      if (this.$store.state.currentUserId) {
        callPlaylistsIndex(this.$store.state.currentUserId)
          .then(response => {
            // sort alphabetically
            this.playlists = response.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
          });
      }
    },
    hideModal() {
      this.$store.commit('hideModal');
    },
    onSongsClick() {
      callAtmoLibrariesIndex(this.$store.state.currentUserId)
        .then(albums => {
          // Use the reduce method to flatten the songs from all albums into a single array
          const allSongs = albums.reduce((songs, album) => {
            // Concatenate the songs of the current album to the accumulated songs
            return songs.concat(album.songs);
          }, []);

          this.allSongs = allSongs;
          this.searchAlbumSongQuery = '';
          this.currentView = 'songs';
        }).catch((err) => {
          console.error(err);
        })
    },
    onAlbumClick(album) {
      this.searchAlbumSongQuery = '';
      this.setCurrentObject('album', album);
    },
    onPlaylistClick(playlist) {
      this.searchAlbumSongQuery = '';
      this.setCurrentObject('playlist', playlist);
    },
    setCurrentObject(objectType, object) {
      this.songs = null;
      this.currentView = objectType;
      this.currentActiveObject = object;

      if (objectType == 'album') {
        const url = `/api/v1/artist_profiles/${object.artist_profile_id}/${objectType}s/${object.id}/${objectType}_songs`;

        Axios.get(url).then(response => {
          this.songs = response.data;
        }).catch((err) => {
          console.error(err);
        })
      } else if (objectType == 'playlist') {
        callSongReferencesIndex(
          {
            songable_id: object.id,
            songable_type: 'Playlist'
          }
        )
          .then(response => {
            this.songs = response.map((songReference) => {
              return songReference.song;
            });
          }).catch((err) => {
            console.error(err);
          })
      }
    },
    // getSongsForTable(url) {
    //   Axios.get(url).then(response => {
    //     this.songs = response.data;
    //   }).catch((err) => {
    //     console.error(err);
    //   })
    // },
    backToLibrary() {
      this.currentView = 'default';
      this.searchAlbumSongQuery = '';
    },
    deleteCurrentPlaylist() {
      const currentPlaylist = this.currentActiveObject;
      if (this.deletePlaylist(currentPlaylist)) {
        this.backToLibrary();
      }
    },
    deletePlaylist(playlist) {
      // TODO: use correct user profile id
      if (!window.confirm(`Are you sure you want to delete playlist "${playlist.name}"?`)) {
        return false;
      }
      if (this.$store.state.currentUserId) {
        Axios.delete(`/api/v1/users/${this.$store.state.currentUserId}/playlists/${playlist.id}`).then(() => {
          this.getPlaylists();
        }).catch((err) => {
          console.error(err);
        });
      }

      return true;
    }
  }
}
</script>
  
<style lang="scss">
@import '@/assets/stylesheets/_variables.scss';

#atmo-library {
  .modal-content {
    border-radius: 5px;
    border: none;
    width: 100vw;
    height: 97vh;
  }
}

.atmo-library {
  .atmo-library__select-view {
    display: flex;
    gap: 1rem;
    font-size: .8rem;
    margin-left: 1.2rem;
    margin-bottom: 1rem;
  }

  .select-view__option {
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: .5rem .5rem .5rem .5rem;
    display: inline-block;
    text-transform: uppercase;
    background: none;
    color: white;
  }

  .modal-lg.modal-dialog {
    max-width: 100vw;
    margin: 1rem;
  }

  .atmo-modal__header {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 2rem;
  }

  .atmo-modal__header-side {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: .5rem;

    img {
      height: 1.5rem;
    }
  }

  .atmo-page-header-container {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  &__body {
    display: flex;
  }

  &__main-column {
    display: flex;
    flex-direction: column;
    flex: 1;

    .ps {
      min-height: 70vh;
    }
  }

  .main-column__scroll-container {
    min-height: 75vh;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 0 20px 10px 30px;
  }

  &__sidebar-section {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  &__section-header {
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
    font-weight: 500;
    color: $atmo-blue--medium;
    text-transform: uppercase;

    &--main {
      justify-content: center;
      font-size: 1.3rem;
      font-weight: 600;
    }
  }

  &__section-header-icon {
    height: 15px;
  }

  &__main-section-header {
    display: flex;
    align-items: center;
    margin: 0rem 0rem 2rem .3rem;
    padding: 0 10px;
  }

  &__main-section-header-actions {
    display: flex;
    justify-content: flex-end;
    height: 20px;
  }

  &__main-section-header-action-icon {
    height: 15px;
    display: none;
  }

  &__main-section-header:hover .atmo-library__main-section-header-action-icon {
    display: inline;
  }

  &__back-to-library {
    display: flex;
    align-items: flex-end;
    font-size: 1.1em;
    font-weight: 300;
    cursor: pointer;

    img {
      height: 12px;
      margin-right: 3px;
    }
  }

  .atmo-input+button {
    margin-left: 3px;
  }
}
</style>
  