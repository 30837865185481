<template>
  <div class="atmo-dashboard" data-cy="atmo-dashboard">
    <h2 class="atmo-page-header">
      Atmo Dashboard
    </h2>
    <div class="atmo-dashboard-container">
      <div class="atmo-dashboard-container__dashboard-content">
        <div class="dashboard-content__recommendations">
          <div class="recommendations__moods-explore-container">
            <suggested-playlists
              :suggestedPlaylists="suggestedPlaylists"
              :suggestedPlaylistsLoading="suggestedPlaylistsLoading"
            />
            <div class="moods-explore-container__explore-container">
              <div class="explore-container__title-container">Explore</div>
              <div class="explore-container__grid-container">
                <router-link class="grid-container__item" :to="{ name: 'atmo_artists' }">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/atmo_artists.png`) + ')' }">
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/artists.png' />
                    <p>Atmo Artists</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="{ name: 'music.charts' }">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/atmo_charts.png`) + ')' }">
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/charts.png' />
                    <p>Atmo Charts</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="{ name: 'products.index' }">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/atmo_store.png`) + ')' }">
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/store.png' />
                    <p>Atmo Store</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="{ name: 'atmo_radio.index' }">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/radio.png`) + ')' }">
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/channel.png' />
                    <p>Atmo Radio</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="`/events/`">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/atmo_channel.png`) + ')' }">
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/radio.png' />
                    <p>Events</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="{ name: 'contests.index' }">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/radio.png`) + ')' }">
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/channel.png' />
                    <p>Contests</p>
                  </div>
                </router-link>
                <!-- <router-link class="grid-container__item" :to="{ name: 'coming_soon' }">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/news_and_blogs.png`) + ')' }">
                    <div class="coming-soon">Soon</div>
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/news_and_blogs.png' />
                    <p>News & Blogs</p>
                  </div>
                </router-link> -->
                <!-- <router-link class="grid-container__item" :to="{ name: 'coming_soon' }">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/artists/${generateRandomString(artistImageArray)}`) + ')' }">
                    <div class="coming-soon">Soon</div>
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/radio.png' />
                    <p>Radio</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="{ name: 'coming_soon' }">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/artists/${generateRandomString(artistImageArray)}`) + ')' }">
                    <div class="coming-soon">Soon</div>
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/channel.png' />
                    <p>Atmo Channel</p>
                  </div>
                </router-link> -->
              </div>
            </div>
          </div>
          <div class="recommendations__category-selection-container">
            <button v-for="(category, index) in dashRecCategoryList" id="category.key" :key="index"
              @click="selectCategoryOrType(category.key, 'dashRecCategoryList')"
              v-bind:class="{ categoryselected: category.isSelected, categorynotselected: !category.isSelected }">
              {{ category.name }}
            </button>
          </div>
          <div class="recommendations__recommended-songs-container">
            <div class="recommended-songs-container__type-selection-container">
              <button v-for="(type, index) in dashRecTypeList" id="type.key" :key="index"
                @click="selectCategoryOrType(type.key, 'dashRecTypeList')"
                v-bind:class="{ typeselected: type.isSelected, typenotselected: !type.isSelected }">
                {{ type.name }}
              </button>
            </div>
            <div class="recommended-songs-container__songs-container">
              <div v-if="recommendedSongsLoading">LOADING</div>
              <carousel :autoplay="true" :autoplayTimeout="5000" :perPage="8" :loop="true">
                <slide v-for="(song, index) in currentRecObjs" :key="index" class="songs-container__song-item">
                  <div class="song-item__image-wrap selected" @click="togglePlayPause(song.song)"
                    v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${getFeaturedImage(song.song.artist_profile.user.images, 'full')})` }">
                    <button class="play-button" name="button" v-show="currentlyPlayingSong !== song.song.id"
                      @click.stop="playSong(song.song)"></button>
                    <button class="pause-button" name="button" v-show="currentlyPlayingSong === song.song.id"
                      @click.stop="pauseSong()"></button>
                  </div>
                  <div class="song-item__content-wrap">
                    <p class="content-wrap__title">{{ song.song.name }}</p>
                    <p class="content-wrap__artist">
                      <router-link class="artist-blue"
                        :to="{ name: 'artist_profiles.show', params: { artistProfileId: song.song.artist_profile_id } }">
                        {{ song.song.artist_profile.user.name }}
                      </router-link>
                    </p>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
        <BroadcastPostList :posts="broadcastPosts" :loading="postsLoading" />
      </div>
      <div class="atmo-dashboard-container__spotlight-songs">
        <div class="spotlight-songs__title-container">
          Spotlight
        </div>
        <div class="spotlight-songs__songs-content-container">
          <div v-if="spotlightSongsLoading">LOADING</div>
          <carousel :autoplay="true" :autoplayTimeout="6000" :perPage="5" :loop="true">
            <slide v-for="(song, index) in spotlightSongs" :key="index" class="songs-content-container__song-item">
              <div class="song-item__play" @click="togglePlayPause(song.song)"
                v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${getFeaturedImage(song.song.artist_profile.user.images, 'full')})` }">
                <!-- Conditionally render play or pause buttons based on whether the song is currently playing -->
                <button class="spotlight-song-play-button" name="button"
                  v-show="currentlyPlayingSong !== song.song.id"></button>
                <button class="spotlight-song-pause-button" name="button"
                  v-show="currentlyPlayingSong === song.song.id"></button>
              </div>
              <div class="song-item__title-artist">
                <p class="title-artist__title">{{ song.song.name }}</p>
                <p class="title-artist__artist">
                  <router-link class="artist-blue"
                    :to="{ name: 'artist_profiles.show', params: { artistProfileId: song.song.artist_profile_id } }">
                    {{ song.song.artist_profile.user.name }}
                  </router-link>
                </p>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import devMockData from '@/devUtils/mockData.js';
import { getFeaturedImage, timeAgo } from '@/helpers/utilityFunctions';
import { mapActions, mapState } from 'vuex';
import BroadcastPostList from '@/components/atmo-dashboards/broadcast-post-list';
import SuggestedPlaylists from '@/components/atmo-dashboards/suggested-playlists'
import {
  callPlaylistsSuggested
} from '@/helpers/axiosCalls';


export default {
  components: {
    BroadcastPostList,
    SuggestedPlaylists
  },
  data() {
    return {
      devMockData,
      selectedCategory: null,
      suggestedPlaylists: [],
      spotlightSongs: [],
      featuredObj: {},
      currentRecObjs: [],
      selectedRecCategory: {
        key: "0",
        name: "Featured this Week",
        catName: "featured",
        typeName: "songs",
        isSelected: true,
      },
      selectedRecType: {
        key: "0",
        name: "Songs",
        catName: "featured",
        typeName: "songs",
        isSelected: true,
      },
      dashRecCategoryList: [
        {
          key: "0",
          name: "Featured this Week",
          catName: "featured",
          typeName: "songs",
          isSelected: true,
        },
        {
          key: "1",
          name: "New Releases",
          catName: "new_releases",
          typeName: "songs",
          isSelected: false,
        },
        {
          key: "2",
          name: "Recommended for you",
          catName: "recommended",
          typeName: "songs",
          isSelected: false,
        },
        {
          key: "3",
          name: "Recently played",
          catName: "recently_played",
          typeName: "songs",
          isSelected: false,
        },
        {
          key: "4",
          name: "Most played",
          catName: "most_played",
          typeName: "songs",
          isSelected: false,
        },
      ],
      dashRecTypeList: [
        {
          key: "0",
          name: "Songs",
          catName: "featured",
          typeName: "songs",
          isSelected: true,
        },
        {
          key: "1",
          name: "Albums",
          catName: "featured",
          typeName: "albums",
          isSelected: false,
        },
        {
          key: "2",
          name: "Artists",
          catName: "featured",
          typeName: "artists",
          isSelected: false,
        },
        {
          key: "3",
          name: "Playlists",
          catName: "featured",
          typeName: "playlists",
          isSelected: false,
        },
      ],
      postsLoading: true,
      suggestedPlaylistsLoading: true,
      recommendedSongsLoading: true,
      spotlightSongsLoading: true,
      currentlyPlayingSong: null,
    }
  },

  created() {
    this.getSuggestedPlaylists();
    this.getSpotlightSongs();
    this.getDashboardRecObjs(`featured/?type=songs&user_id=${this.$store.state.currentUserId}`);
  },
  mounted() {
    this.$store.dispatch('userActivities/getBroadcastPosts', {
      userId: this.$store.state.currentUserId,
      params: {
        date_range: 'all',
        limit: 100,
        show_current_user_posts: 'true'
      }
    });
    this.createUserAccomplishments({
      userId: this.$store.state.currentUserId,
      accomplishmentData: {
        payloads: [
          {
            user_id: this.$store.state.currentUserId,
            category: 'logins',
            sub_category: 'loginNumber'
          },
          {
            user_id: this.$store.state.currentUserId,
            category: 'logins',
            sub_category: 'loginTime'
          },
          {
            user_id: this.$store.state.currentUserId,
            category: 'logins',
            sub_category: 'loginStreak'
          }
        ]
      }
    });
  },
  computed: {
    ...mapState('userActivities', ['broadcastPosts', 'postsLoading']),
    isPlaying() {
      return this.$store.state.player.isPlaying; // Assuming you have this state in your Vuex store
    },
  },
  methods: {
    ...mapActions('accomplishments', ['createUserAccomplishments']),
    togglePlayPause(song) {
      // Check if the song is currently playing
      if (this.currentlyPlayingSong === song.id && this.isPlaying) {
        this.pauseSong(); // Pause if it's already playing
      } else {
        this.playSong(song); // Play if it's not playing
      }
    },
    playSong(song) {
      this.currentlyPlayingSong = song.id;
      this.$store.dispatch('player/prependAndPlaySong', song); // Dispatch play action
    },
    pauseSong() {
      this.$store.dispatch('player/pauseSong'); // Dispatch pause action
      this.currentlyPlayingSong = null;
    },
    getTimeAgo(date) {
      return timeAgo(date);
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    showPauseButton(index) {
      this.currentlyPlayingSong = index;
    },
    showPlayButton() {
      this.currentlyPlayingSong = null;
    },
    selectCategoryOrType(key, listName) {
      for (let i = 0; i < this[`${listName}`].length; i++) {
        if (this[`${listName}`][i].key !== key) {
          this[`${listName}`][i].isSelected = false
        }
      }
      this.toggleSelection(key, listName)
    },
    toggleSelection(key, listName) {
      const obj = this[`${listName}`].find(item => item.key === key);

      if (listName === "dashRecCategoryList") {
        this.selectedRecCategory = obj;
      } else if (listName === "dashRecTypeList") {
        this.selectedRecType = obj;
      }

      this.getDashboardRecObjs(`${this.selectedRecCategory.catName}/?type=${this.selectedRecType.typeName}&user_id=${this.$store.state.currentUserId}`);
      if (obj) {
        obj.isSelected = !obj.isSelected
      }
    },
    getDashboardRecObjs(routeInfo) {
      console.log(`api/v1/dashboard_recs/${routeInfo}`);
      this.recommendedSongsLoading = true;
      Axios.get(`api/v1/dashboard_recs/${routeInfo}`, {
      })
        .then((response) => {
          this.currentRecObjs = response.data;
          this.recommendedSongsLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSpotlightSongs() {
      Axios.get(`/api/v1/dashboard_recs/spotlight?user_id=${this.$store.state.currentUserId}`, {
      })
        .then((response) => {
          this.spotlightSongs = response.data;
          this.spotlightSongsLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSuggestedPlaylists() {
      callPlaylistsSuggested({
        user_id: this.$store.state.currentUserId,
        length: 3
      })
        .then((response) => {
          this.suggestedPlaylists = response;
          console.log("SUGGESTED PLAYLISTS", this.suggestedPlaylists);
          this.suggestedPlaylistsLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // getArtists() {
    //   console.log("BASE_URL", publicPath);
    // },
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.artist-blue {
  color: $atmo-blue--medium;
}

.friend-white {
  color: white;
}

//https://github.com/mercs600/vue2-perfect-scrollbar
.ps {
  max-height: 27rem;
}

//https://ssense.github.io/vue-carousel/examples/
.VueCarousel-pagination {
  display: none;
}

.atmo-dashboard {
  margin: 0 75px;
}

.atmo-dashboard-container {
  // border: 1px solid white;

  &__dashboard-content {
    // border: 1px solid red;
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 200px;
  }

  .recommendations__moods-explore-container {
    display: grid;
    gap: 30px;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 1rem;
  }

  .explore-container__title-container {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .explore-container__grid-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr)
  }

  .grid-container__item {
    position: relative;
  }

  .grid-container__item .coming-soon {
    background: rgba(154, 45, 101, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0px 5px 0px 5px;
    padding: 3px 7px 3px 7px;
    text-transform: uppercase;
  }

  .item__image-wrap {
    height: 100px;
    width: 100%;
    background-color: rgba(96, 72, 117, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-blend-mode: multiply;
    font-weight: 500;
    // background-image: url('@/assets/images/artists/deadmau5.png')
  }

  .image-wrap__image {
    height: 40px;
    object-fit: contain;
    margin-bottom: .5rem;
  }

  .recommendations__category-selection-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 1rem;
  }

  .category-selection-container__category-button {
    border: 1px solid white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem;
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
  }

  .categoryselected {
    border: 1px solid white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem;
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .categorynotselected {
    border: 1px solid white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem;
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
    background: transparent;
  }

  .recommended-songs-container__type-selection-container {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    max-width: 25rem;
  }

  .typeselected {
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
    border: none;
    padding: .5rem 1rem .5rem 1rem;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 20px;
  }

  .typenotselected {
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
    border: none;
    padding: .5rem 1rem .5rem 1rem;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    background-image: unset;
  }


  .recommended-songs-container__songs-container {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    margin-bottom: 1rem;
  }

  .recommended-songs-container__type-selection-container {
    margin-bottom: 1rem;
  }

  .songs-container__song-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .song-item__image-wrap {
    height: 100px;
    width: 100px;
    // background-color: $atmo-purple--dark;
    border-radius: 100%;
    margin-bottom: .5rem;
    // background-size: auto;
    // background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .song-item__image-wrap.selected {
    border: 2px solid $atmo-blue--light;
  }

  .play-button {
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 20px;
    border-color: transparent transparent transparent white;
    transition: 100ms all ease;
    cursor: pointer;
    // play state
    border-style: solid;
    border-width: 10px 0 10px 20px;

    // &.paused {
    //   border-style: double;
    //   border-width: 0px 0 0px 20px;
    // }

    &:hover {
      border-color: transparent transparent transparent $atmo-blue--light;
    }
  }

  .pause-button {
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 20px;
    border-color: transparent transparent transparent white;
    transition: 100ms all ease;
    cursor: pointer;
    // play state
    border-style: double;
    border-width: 0px 0 0px 20px;

    &:hover {
      border-color: transparent transparent transparent $atmo-blue--light;
    }
  }

  .title-artist__artist {
    color: $atmo-blue--medium;
  }

  .content-wrap__title {
    text-transform: uppercase;
    margin-bottom: .2rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0rem .5rem 0rem 0rem;
    max-width: 14ch;
  }

  .content-wrap__artist {
    color: $atmo-blue--medium;
  }

  &__spotlight-songs {
    // border: 1px solid green;
    min-height: 50px;
    max-height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  .spotlight-songs__title-container {
    margin-right: 20px;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase
  }

  .spotlight-songs__songs-content-container {
    max-width: 75rem;
    overflow-x: hidden;
  }

  .songs-content-container__song-item {
    display: flex;
    flex-direction: row;
    padding: 0rem 1rem 0rem 1rem;
    border-right: 1px solid white;
  }

  .song-item__play {
    margin-right: 10px;
    background-color: $atmo-purple--dark;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .spotlight-song-play-button {
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 10px;
    border-color: transparent transparent transparent white;
    transition: 100ms all ease;
    cursor: pointer;

    // play state
    border-style: solid;
    border-width: 5px 0 5px 10px;

    // &.paused {
    //   border-style: double;
    //   border-width: 0px 0 0px 10px;
    // }

    &:hover {
      border-color: transparent transparent transparent $atmo-blue--light;
    }
  }

  .spotlight-song-pause-button {
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 10px;
    border-color: transparent transparent transparent white;
    transition: 100ms all ease;
    cursor: pointer;

    // play state
    border-style: double;
    border-width: 0px 0 0px 10px;

    &:hover {
      border-color: transparent transparent transparent $atmo-blue--light;
    }
  }

  .song-item__title-artist {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title-artist__title {
    margin-bottom: .2rem;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0rem .5rem 0rem 0rem;
    max-width: 14ch;
  }
}
</style>
