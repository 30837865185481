<template>
  <div class="atmo-album-index">
    <h2 class="atmo-page-header">
      Artist Albums
    </h2>
    <div class="atmo-album-index__albums">
      <atmo-upload-card
        v-if="canCreateAlbum"
        class="atmo-album-card"
        component-type="router-link"
        alt="Upload Album"
        :to="{ name: 'albums.new', params: { artistProfileId: artistProfileId }}"
      >
        Upload Album
      </atmo-upload-card>
      <album-card
        v-for="album in albums"
        :key="album.id"
        :album="album"
      />
    </div>
  </div>
</template>

<script>
  import AtmoUploadCard from '@/components/atmo-upload-card';
  import AlbumCard from './album-card';
  import Axios from 'axios';

  export default {
    components: {
      AtmoUploadCard,
      AlbumCard
    },

    data() {
      return {
        albums: null
      };
    },

    computed: {
      artistProfileId() {
        return Number(this.$route.params.artistProfileId);
      },
      canCreateAlbum() {
        const { currentUserProfileType, currentUserProfileId } = this.$store.state;
        return (
          currentUserProfileType === 'ArtistProfile'
          && currentUserProfileId === this.artistProfileId
        );
      }
    },
    created() {
      this.getAlbums();
    },

    methods: {
      getAlbums () {
        Axios.get(
          `/api/v1/artist_profiles/${this.artistProfileId}/albums`
        ).then((response) => {
          this.albums = response.data;
          console.log("this.albums: ", this.albums);
        }).catch((error) => {
          console.error(error);
        });
      },
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .atmo-album-index {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__albums {
      display: grid;
      grid-template-columns: repeat( auto-fit, 250px );
      justify-items: center;
      justify-content: center;
      grid-gap: 10px;
      width: 100%;
      max-width: 1320px;
      max-height: 746px;
      margin-top: 20px;
      overflow: auto;
    }
  }
</style>
