<template>
  <perfect-scrollbar>
    <div class="songs-grid">
      <div v-for="(song, index) in songs" :key="index" class="songs-grid__song-card">
        <router-link :to="{ name: 'songs.show', params: { songId: song.id } }">
          <div class="song-card__image-wrap"
            v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${song.album.image.url})` }">
            <div class="image-wrap__album-label">{{ song.album.name }}</div>
          </div>
        </router-link>
        <div class="song-card__text-wrap">
          <div class="text-wrap__song-wrap">{{ song.name }}</div>
          <div class="text-wrap__artist-wrap">{{ song.artist_profile.user.name }}</div>
          <atmo-voting-buttons :entity="song" voteableType="Song" />
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import PaginationMixin from '@/mixins/pagination';
import AtmoVotingButtons from '@/components/atmo-voting-buttons';

export default {
  mixins: [PaginationMixin],
  props: { songs: { type: Array, required: true }, },
  components: {
    AtmoVotingButtons
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "@/assets/stylesheets/_variables.scss";

.songs-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin-top: 10px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 1rem;

  .songs-grid__song-card {
    border-radius: 5px;
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
  }

  .song-card__image-wrap {
    min-height: 8rem;
    flex: 1;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 800;
    color: #fff;
    position: relative;
  }

  .image-wrap__album-label {
    position: absolute;
    top: .4rem;
    left: .4rem;
    padding: 0.3rem 1rem 0.3rem 1rem;
    z-index: 1;
    font-size: .8rem;
    font-weight: 400;
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 10px;
  }

  .song-card__text-wrap {
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    // height: 2rem;
    border-radius: 0 0 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem .5rem 1rem;
    margin-top: auto;
    position: relative;
  }

  .vote-buttons-container {
    align-items: center;
    position: absolute;
    right: -.7rem;
  }

  .vote-buttons-container__up-button {
    background-color: $atmo-blue--medium;
    padding: .4rem 1rem .4rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 4rem;
    cursor: pointer;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .7);

    span {
      margin-right: 0.2rem;
      font-weight: 600;
    }

    img {
      height: .8rem;
    }
  }

  .vote-buttons-container__down-button {
    background-color: $atmo-pink--medium;
    padding: .4rem 1rem .4rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 4rem;
    cursor: pointer;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .7);

    span {
      margin-right: 0.2rem;
      font-weight: 600;
    }

    img {
      height: .8rem;
    }
  }

  .text-wrap__song-wrap {
    font-weight: 500;
    color: white;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 16ch;
  }

  .text-wrap__artist-wrap {
    color: white;
  }
}
</style>
