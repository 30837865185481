<template>
  <perfect-scrollbar>
    <div class="playlists-grid">
      <div v-for="(playlist, index) in playlists" :key="index" class="playlists-grid__playlist-card">
        <router-link :to="{ name: 'playlists.show', params: { playlistId: playlist.id } }">
          <div class="playlist-card__image-wrap" :style="backgroundImageStyle(playlist)">
          </div>
        </router-link>
        <div class="playlist-card__text-wrap">
          <div class="text-wrap__playlist-wrap">{{ playlist.name }}</div>
          <atmo-voting-buttons :entity="playlist" voteableType="Playlist" />
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import PaginationMixin from '@/mixins/pagination';
import AtmoVotingButtons from '@/components/atmo-voting-buttons';
import { getFeaturedImage } from '@/helpers/utilityFunctions';

export default {
  mixins: [PaginationMixin],
  props: { showNewReleases: { type: Boolean, default: false }, playlists: { type: Array, required: true }, },
  components: {
    AtmoVotingButtons
  },
  data() {
    return {};
  },
  methods: {
    backgroundImageStyle(playlist) {
      // Return a fallback style if playlist is undefined or does not contain song references
      if (!playlist || !playlist.song_references || playlist.song_references.length === 0) {
        return {
          backgroundImage: this.generateGradientBackground(),
        };
      }

      return {
        backgroundImage: this.generateGradientBackground() + ', ' + this.getPlaylistImage(playlist),
      };
    },
    generateGradientBackground() {
      return 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)';
    },
    getPlaylistImage(playlist) {
      const randomSongReference = this.pickRandomSongReference(playlist);
      if (!randomSongReference || !randomSongReference.song.artist_profile.user.images) {
        return 'url(/path/to/default-image.png)'; // Fallback image if the song reference is invalid
      }
      const imageUrl = this.getFeaturedImage(randomSongReference.song.artist_profile.user.images, 'full');
      return `url(${imageUrl})`;
    },
    pickRandomSongReference(playlist) {
      const { song_references } = playlist;
      const randomIndex = Math.floor(Math.random() * song_references.length);
      return song_references[randomIndex];
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/stylesheets/_variables.scss";

.playlists-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin-top: 10px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 1rem;

  .playlists-grid__playlist-card {
    border-radius: 5px;
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
  }

  .playlist-card__image-wrap {
    min-height: 8rem;
    flex: 1;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 800;
    color: #fff;
    position: relative;
  }

  .image-wrap__album-label {
    position: absolute;
    top: .4rem;
    left: .4rem;
    padding: 0.3rem 1rem 0.3rem 1rem;
    z-index: 1;
    font-size: .8rem;
    font-weight: 400;
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 10px;
  }

  .playlist-card__text-wrap {
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    // height: 2rem;
    border-radius: 0 0 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem .5rem 1rem;
    margin-top: auto;
    position: relative;
  }

  .vote-buttons-container {
    align-items: center;
    position: absolute;
    right: -.7rem;
  }

  .vote-buttons-container__up-button {
    background-color: $atmo-blue--medium;
    padding: .4rem 1rem .4rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 4rem;
    cursor: pointer;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .7);

    span {
      margin-right: 0.2rem;
      font-weight: 600;
    }

    img {
      height: .8rem;
    }
  }

  .vote-buttons-container__down-button {
    background-color: $atmo-pink--medium;
    padding: .4rem 1rem .4rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 4rem;
    cursor: pointer;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .7);

    span {
      margin-right: 0.2rem;
      font-weight: 600;
    }

    img {
      height: .8rem;
    }
  }

  .text-wrap__playlist-wrap {
    font-weight: 500;
    color: white;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 16ch;
  }

  .text-wrap__artist-wrap {
    color: white;
  }
}
</style>