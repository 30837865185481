<template>
    <section class="atmo-ad">
        <div class="atmo-ad__advertise_wrap" v-if="showAtmoAdvertLink">
            <router-link :to="{ name: 'advertisements.index', params: {userId: currentUser.id } }">
                Advertise on Atmo
            </router-link>
        </div>
        <div class="atmo-ad__ad-container" v-if="randomPlatformAd.image">
            <div class="ad-container__ad"
                v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${randomPlatformAd.image.url})` }">
            </div>
            <div class="ad-container__title-wrap">{{ randomPlatformAd.title }}</div>
            <div class="ad-container__description-wrap">{{ randomPlatformAd.copy }}</div>
            <a :href="randomPlatformAd.link" target="_blank" class="ad-container__link">
                {{ randomPlatformAd.link_label }}
            </a>
        </div>
    </section>
</template>
  
<script>
import Axios from 'axios';
import { callUserProfilesShow, callUsersShow } from '@/helpers/axiosCalls';

export default {
    props: {
        showAtmoAdvertLink: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
        return {
            currentUserProfile: {},
            currentUser: {},
            platformAds: [],
            randomPlatformAd: {},
        }
    },
    computed: {

    },
    created() {
        this.getCurrentUser();
        this.getPlatformAds();
    },
    methods: {
        getPlatformAds() {
            Axios.get(`/api/v1/users/1/platform_ads`, {
            })
                .then((response) => {
                    this.platformAds = response.data;
                    this.randomPlatformAd = this.platformAds[Math.floor(Math.random() * this.platformAds.length)];
                    // console.log("RANDOM AD: ", this.randomPlatformAd);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        getCurrentUserProfile(profileId) {
            callUserProfilesShow(profileId)
                .then((response) => {
                    this.currentUserProfile = response;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        getCurrentUser() {
            callUsersShow(this.$store.state.currentUserId)
                .then((response) => {
                    this.currentUser = response;
                    this.getCurrentUserProfile(this.currentUser.profile_id);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    }
}
</script>
  
<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-ad {
    margin-top: 3rem;

    &__advertise_wrap {
        a {
            color: $atmo-gray--light--faded;
            text-transform: uppercase;
        }
    }

    &__ad-container {
        margin-top: 10px;
        padding: .6rem;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        min-height: 14rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .ad-container__ad {
        height: 5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        margin-bottom: 1rem;
    }

    .ad-container__title-wrap {
        font-size: 1rem;
        margin-bottom: 0.4rem;
        max-width: 8.5rem;
    }

    .ad-container__description-wrap {
        font-size: .8rem;
        margin-bottom: 1rem;
        max-width: 8.5rem;
    }

    .ad-container__link {
        margin-top: auto;
        display: inline-block;
        border: 1px solid white;
        border-radius: 3px;
        text-transform: uppercase;
        padding: .2rem .5rem .2rem .5rem;
        color: white;
        font-size: .7rem;
        width: 100%;
        text-align: center;
    }
}
</style>
  