<template>
  <div class="atmo-radio-page" data-cy="atmo-radio-page">
    <h2 class="atmo-page-header">Atmo Radio</h2>

    <div class="atmo-radio-page__content-grid">
      <div class="content-grid__ads-container">
        <atmo-ad :showAtmoAdvertLink="false" />
        <atmo-ad :showAtmoAdvertLink="false" />
      </div>
      <div class="atmo-radio-container">
        <div class="search-container__background-container">
          <input class="search-field-rounded" type="text" v-model="searchQuery" @keypress.enter="submitSearchQuery" placeholder="Search artists, albums, songs, genres, and moods" />
        </div>
        <div class="selected-radio-container" v-if="!stationDataIsEmpty">
          <div class="selected-radio-container_heading-container">
            <h3>{{ stationData.type }} - {{ stationData.query }} Radio</h3>
            <song-actions-popover :song="song" placement="right" :actions="['create-playlist-from-station', 'add-to-library', 'add-to-queue', 'add-to-playlist']" />
          </div>
          <div class="selected-radio-container__details-container">
            <div class="details-container__album-container">
              <div class="album-container__album-wrap">
                <img v-if="stationSongs.length > 0" class="album-wrap__image" :src="stationSongs[0].album.image.url" alt="Radio Song Image" />
              </div>
            </div>
            <div class="details-container__recent-stations-container">
              <div class="recent-stations-container__heading-wrap">
                <p>Recent Stations</p>
              </div>
              <div class="recent-stations-container__stations-container">
                <div class="stations-container__station" v-for="(station, index) in userRadioStations" :key="index" @click="getUserRadioStationSongs(station.id)">{{ station.name }}</div>
              </div>

              <div class="recent-stations-container__settings-container">
                <div class="settings-container__heading">Radio Settings</div>
                <div class="settings-container__excluded-artists">
                  <div class="excluded-artists__heading" @click="toggleExcludedArtists()">Excluded Artists</div>
                  <div v-if="excludedArtistsIsClicked">
                    <div class="excluded-artists__artist" v-for="index in 5" :key="index">{{ generateRandomString(devMockData.artistNameArray) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="selected-radio-container__song-details-container">
            <div class="song-details-container__play-button-wrap">
              <!-- <a class="player-bar__play-pause">
                                <img v-show="isPlaying" class="player-bar__icon player-bar__icon--play-pause"
                                    src="@/assets/images/icons/pause.png" @click="pauseSong" />
                                <img v-show="!isPlaying" class="player-bar__icon player-bar__icon--play-pause"
                                    :class="{ 'player-bar__icon--disabled': !currentSong }"
                                    src="@/assets/images/icons/play.png" @click="switchToRadioMode" />
                            </a> -->
            </div>
            <div class="song-details-container__song-artist-container">
              <div class="song-artist-container__song-container">
                <router-link class="white-link" :to="{ name: 'songs.show', params: { artistProfileId: stationSongs[0].artist_profile.id, songId: stationSongs[0].id } }">
                  {{ stationSongs[0].name }}
                </router-link>
              </div>
              <div class="song-artist-container__artist-container">
                <router-link class="blue-link" :to="{ name: 'albums.show', params: { artistProfileId: stationSongs[0].artist_profile.id, albumId: stationSongs[0].album.id } }">
                  {{ stationSongs[0].album.name }}
                </router-link>
              </div>
              <div class="song-artist-container__label-container">
                <router-link :to="{ name: 'label_profiles.show', params: { labelProfileId: stationSongs[0].label_profile.id } }">
                  {{ stationSongs[0].label_profile_user.name }}
                </router-link>
              </div>
            </div>
            <div class="song-details-container__share-container">
              <song-actions-popover :song="song" placement="right" :actions="['add-to-library', 'add-to-queue', 'add-to-playlist']" />
            </div>
          </div>
        </div>
        <div class="selected-radio-container" v-if="stationDataIsEmpty">
          <div class="selected-radio-container_heading-container">
            <h3>Search for a radio station</h3>
          </div>
          <div class="selected-radio-container__details-container">
            <div class="details-container__album-container">
              <div class="album-container__album-wrap">
                <div class="empty-radio-placeholder"></div>
              </div>
            </div>
            <div class="details-container__recent-stations-container">
              <div class="recent-stations-container__heading-wrap">
                <p>Recent Stations</p>
              </div>
              <div class="recent-stations-container__stations-container">
                <div class="stations-container__station" v-for="(station, index) in userRadioStations" :key="index" @click="getUserRadioStationSongs(station.id)">{{ station.name }}</div>
              </div>

              <div class="recent-stations-container__settings-container">
                <div class="settings-container__heading">Radio Settings</div>
                <div class="settings-container__excluded-artists">
                  <div class="excluded-artists__heading" @click="toggleExcludedArtists()">Excluded Artists</div>
                  <div v-if="excludedArtistsIsClicked">
                    <div class="excluded-artists__artist" v-for="index in 5" :key="index">{{ generateRandomString(devMockData.artistNameArray) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty-radio-song-details-container"></div>
        </div>
      </div>
      <div class="content-grid__ads-container">
        <atmo-ad :showAtmoAdvertLink="false" />
        <atmo-ad :showAtmoAdvertLink="false" />
      </div>
    </div>
  </div>
</template>

<script>
import SongActionsPopover from "@/components/song-actions-popover";
import AtmoAd from "@/components/atmo-ad";
import devMockData from "@/devUtils/mockData.js";

export default {
  components: { AtmoAd, SongActionsPopover },
  data() {
    return {
      devMockData: devMockData,
      excludedArtistsIsClicked: false,
      searchQuery: "",
    };
  },
  computed: {
    // Get the current song and playing state from Vuex store
    currentSong() {
      return this.$store.state.player.currentSong;
    },
    isPlaying() {
      return this.$store.state.player.isPlaying;
    },
    stationDataIsEmpty() {
      return this.$store.getters["atmoRadioStations/stationDataIsEmpty"];
    },
    stationData() {
      return this.$store.getters["atmoRadioStations/stationData"];
    },
    userRadioStations() {
      return this.$store.getters["atmoRadioStations/userRadioStations"];
    },
    stationSongs() {
      return this.$store.getters["atmoRadioStations/stationSongs"];
    },
    song() {
      return this.$store.getters["atmoRadioStations/currentSong"];
    },
  },

  created() {
    this.$store.dispatch("atmoRadioStations/fetchUserRadioStations");
  },

  methods: {
    async switchToRadioMode() {
      if (this.stationSongs.length > 0) {
        // Set the queue mode to 'radio'
        await this.$store.dispatch("atmoQueues/setQueueMode", "radio");
        console.log("Queue mode set to:", this.$store.state.atmoQueues.queueMode);

        // Commit the switchToRadioMode mutation to configure the player for radio mode
        this.$store.commit("player/switchToRadioMode");
        console.log("Radio mode configured: autoplay enabled, shuffle off, repeat off");

        // Set the active queue to the station songs
        this.$store.commit("atmoQueues/setActiveQueue", this.stationSongs);

        // Ensure Howl instances are loaded for all songs in the queue
        await this.$store.dispatch("player/updateSongHowls", this.stationSongs);

        // Now play the first song in the station
        this.$store.dispatch("player/playSong", this.stationSongs[0]);
      }
    },
    pauseSong() {
      // Pause the current song
      this.$store.dispatch("player/pauseSong");
    },
    async getUserRadioStationSongs(id) {
      await this.$store.dispatch("atmoRadioStations/fetchUserRadioStationSongs", {
        station_id: id,
        user_id: this.$store.state.currentUserId,
        length: 10,
      });
      this.switchToRadioMode();
    },
    toggleExcludedArtists() {
      this.excludedArtistsIsClicked = !this.excludedArtistsIsClicked;
    },
    async submitSearchQuery() {
      if (this.searchQuery) {
        await this.$store.dispatch("atmoRadioStations/fetchSearchResults", { query: this.searchQuery });
        this.openSearchResultsModal();
      }
    },
    openSearchResultsModal() {
      this.$store.commit("atmoRadioStations/toggleSearchResultsModal");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-radio-page {
  margin: 0 75px;
}

.atmo-page-header {
  display: flex;
  justify-content: center;
}

.atmo-radio-page__content-grid {
  display: grid;
  grid-template-columns: 11rem 1fr 11rem;
}

.atmo-radio-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .search-container__background-container {
    background: rgba(216, 216, 216, 0.1);
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 2rem;
    min-width: 20vw;
    max-width: 50vw;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.4);
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.4);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.4);
  }

  .search-field-rounded {
    background: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    color: white;
    width: 100%;
    position: relative;
    background: url("@/assets/images/icons/search.png") no-repeat scroll 7px 7px;
    background-size: 1rem;
    background-position: 0.8rem 0.5rem;
  }

  .selected-radio-container_heading-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    gap: 1rem;
  }

  .selected-radio-container__details-container {
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
  }

  .album-wrap__image {
    max-height: 20rem;
    max-width: 20rem;
    border-radius: 5px;
  }

  .recent-stations-container__heading-wrap {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
  }

  .recent-stations-container__stations-container {
    margin-bottom: 2rem;
  }

  .stations-container__station {
    cursor: pointer;
  }

  .stations-container__station + .stations-container__station {
    margin-top: 0.5rem;
  }

  .settings-container__heading {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
  }

  .excluded-artists__heading {
    cursor: pointer;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  .excluded-artists__artist + .excluded-artists__artist {
    margin-top: 0.5rem;
  }

  // .song-artist-container__song-container {
  //     display: flex;
  //     justify-content: center;
  // }

  .selected-radio-container__song-details-container {
    margin-bottom: 0.5rem;
    display: flex;
    gap: 1rem;
  }

  .white-link {
    display: inline-block;
    font-size: 1rem;
    color: white;
  }

  .blue-link {
    color: $atmo-blue--medium;
  }

  .song-artist-container__song-container {
    margin-bottom: 0.5rem;
  }

  .song-artist-container__artist-container {
    margin-bottom: 0.5rem;
  }

  // .song-artist-container__label-container {

  // }

  .song-details-container__share-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-radio-placeholder {
    height: 240px;
    width: 305px;
    border-radius: 5px;
    background-image: linear-gradient(-270deg, $atmo-pink--medium 0%, $atmo-blue--light 100%);
  }

  .empty-radio-song-details-container {
    height: 67px;
    width: 460px;
  }
}
</style>
