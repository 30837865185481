import {
  callSongPlayCountsShow,
  callSongPlayCountsCreate,
  callSongPlayCountsUpdate
} from '@/helpers/axiosCalls';

export default {
  async fetchSongPlayCount(songId) {
    try {
      const response = await callSongPlayCountsShow(songId);
      return response.data;
    } catch (error) {
      console.error('Error fetching song play count:', error);
      return null;
    }
  },

  async createSongPlay(songId, userId) {
    try {
      await callSongPlayCountsCreate({ song_id: songId, play_count: 1 });
      await callSongPlayCountsCreate({ song_id: songId, user_id: userId, play_count: 1 });
    } catch (error) {
      console.error('Error creating song play:', error);
    }
  },

  async updateSongPlay(songId, currentPlayCount) {
    try {
      await callSongPlayCountsUpdate(songId, { play_count: currentPlayCount + 1 });
    } catch (error) {
      console.error('Error updating song play:', error);
    }
  },

  async handleUserSongPlay(songId, userId) {
    try {
      const response = await callSongPlayCountsShow(songId, { user_id: userId });
      const userSongPlay = response.data;

      if (!userSongPlay) {
        await callSongPlayCountsCreate({ song_id: songId, user_id: userId, play_count: 1 });
      } else {
        await callSongPlayCountsUpdate(songId, { play_count: userSongPlay.play_count + 1 });
      }
    } catch (error) {
      console.error('Error handling user song play:', error);
    }
  }
};
