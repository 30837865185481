<template>
  <div class="atmo-charts-show">
    <header class="atmo-charts-show__header">
      <atmo-back-link></atmo-back-link>
      <span class="atmo-charts-show__title atmo-flex-child--equal-width">
        {{ chart.chart_type || chartTitle }}
      </span>
      <div class="atmo-flex-child--equal-width" />
    </header>

    <div class="atmo-songs-list">
      <perfect-scrollbar v-if="chart && chart.songs.length > 0">
        <div class="atmo-songs-list__songs">
          <div v-for="(song, index) in chart.songs" :key="index"
            :class="index == 1 ? 'atmo-song atmo-song--active' : 'atmo-song'">
            <div class="atmo-song__plays"> ▶ {{ formatSongPlayCount(song.play_count) }} </div>
            <div class="atmo-song__background-image-container"
              v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${song.album.image.url})` }">
              <span class="atmo-song__ranking"> #{{ index + 1 }} </span>
            </div>
            <div class="atmo-song__info">
              <router-link :to="{ name: 'songs.show', params: { songId: song.id } }" class="atmo-song__song-name"> {{ song.name }}</router-link>
              <router-link :to="{ name: 'artist_profiles.show', params: { artistProfileId: song.artist_profile_id } }" class="atmo-song__artist-name"> {{ song.artist_profile.user.name }} </router-link>
            </div>
            <atmo-voting-buttons :entity="song" voteableType="Song" />
          </div>
        </div>
      </perfect-scrollbar>
      <div v-else><generic-empty-message message="No songs in this chart"/></div>
      <!-- <div class="atmo-songs-list__footer">
        <div class="atmo-paginator atmo-songs-list__paginator">
          <button class="atmo-paginator__button atmo-paginator__button--disabled atmo-paginator__button--vertical">
            <img :src="require('@/assets/images/icons/music/arrow_backword.png')" alt="previous"></button>
          <button class="atmo-paginator__button atmo-paginator__button--vertical">
            <img :src="require('@/assets/images/icons/music/arrow_forward.png')" alt="next"></button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import AtmoVotingButtons from '@/components/atmo-voting-buttons.vue';
import AtmoBackLink from '@/components/atmo-back-link.vue';
import GenericEmptyMessage from '@/components/generic-empty-message.vue';
import { mapGetters } from 'vuex';
import { formatSongPlayCount } from '@/helpers/utilityFunctions';

export default {
  components: { AtmoVotingButtons, AtmoBackLink, GenericEmptyMessage },

  data() {
    return {
      chart: null,
    };
  },
  computed: {
    ...mapGetters('charts', [
      'getMostPlayedChart',
      'getTopVotedChart',
      'getTopYearChart',
      'getGenreCharts',
      'getMoodCharts'
    ]),
    yearForRecent() {
      return new Date().getFullYear();
    },
    chartTitle() {
      return this.$route.params.chart_name.replace(/_/g, ' ').toUpperCase();
    },
    selectedChart() {
      const chartName = this.$route.params.chart_name.toLowerCase();
      console.log("SELECTED CHART", this.$route.params.chart_name.toLowerCase())

      switch (chartName) {
        case 'most-played': {
          return this.getMostPlayedChart;
        }
        case 'top-voted': {
          return this.getTopVotedChart;
        }
        case `top-year-${this.yearForRecent}`: {
          return this.getTopYearChart;
        }
        default: {
          // Check if it's a genre or mood chart
          const genreChart = this.getGenreCharts.find(chart => chart.genre.name.toLowerCase() === chartName);
          const moodChart = this.getMoodCharts.find(chart => chart.mood.name.toLowerCase() === chartName);
          return genreChart || moodChart || null;
        }
      }
    }
  },
  created() {
    this.getChart();
  },
  methods: {
    formatSongPlayCount,
    getChart() {
      this.chart = this.selectedChart;
    },
  },
  watch: {
    '$route.params.chart_name': 'getChart' // Update the chart if the route changes
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-charts-show {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    display: flex;
    margin-top: 3px;
    margin-bottom: 12px;
  }

  &__title {
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
  }

  .atmo-songs-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: -4px 0;

    &__songs {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &__footer {
      display: flex;
      justify-content: center;
    }
  }

  .atmo-song {
    width: 99%;
    display: flex;
    align-items: center;
    background-image: linear-gradient(-270deg,
        rgba($atmo-blue--medium, 0.4) 10%,
        rgba($atmo-pink--medium, 0.4) 100%);
    margin: 6px 0px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;

    &:hover {
      background-image: linear-gradient(-270deg,
          $atmo-blue--medium 10%,
          $atmo-pink--medium 100%);
    }

    &--active {
      background-image: linear-gradient(-270deg,
          $atmo-blue--medium 10%,
          $atmo-pink--medium 100%);
    }

    &__plays {
      margin: 1rem 1rem 1rem 1rem;
      padding: .5rem 1rem .5rem 1rem;
      border: 1px solid white;
      border-radius: 15px;
      font-size: 0.8em;
      min-width: 4rem;
    }

    &__background-image-container {
      height: 3.8rem;
      width: 3.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__ranking {
      z-index: 0;
      font-weight: 700;
      font-size: 1.2rem;
    }

    &__info {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    &__song-name {
      text-transform: uppercase;
      font-size: 1rem;
      margin: 2px 0;
      color: white;
    }

    &__artist-name {
      font-size: .8rem;
      color: white;
    }
  }
}
</style>
