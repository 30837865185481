import Vue from 'vue'
import Vuex from 'vuex'
import Axios from "axios";
import { setDefaultAxiosHeaders } from '@/helpers/axios';
import playerModule from "./store/player";
import opsModule from "./store/ops";
import eventsModule from "./store/events";
import addressModule from "./store/address";
import contestsModule from "./store/contests";
import productsModule from "./store/products";
import accomplishmentsModule from "./store/accomplishments";
import notificationsModule from "./store/notifications";
import userFriendshipsModule from "./store/userFriendships";
import userRelationshipsModule from "./store/userRelationships";
import profilesModule from "./store/profiles";
import postsModule from "./store/posts";
import liveListeningRoomsModule from "./store/liveListeningRooms";
import chatRoomsModule from "./store/chatRooms";
import musicProfilesModule from "./store/musicProfiles";
import userActivitiesModule from "./store/userActivities";
import playlistsModule from "./store/playlists";
import chartsModule from "./store/charts";
import atmoRadioStationsModule from "./store/atmoRadioStations";
import atmoQueuesModule from "./store/atmoQueues";
import librariesModule from "./store/libraries";
import videoPlayerModule from "./store/videoPlayer";
import User from "@/models/user";

Axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

Vue.use(Vuex);

const defaultState = () => ({
  token: null,
  authenticated: false,
  authenticationPromise: null,
  currentUserId: null,
  currentUserEmail: null,
  currentUserName: null,
  currentUserProfileId: null,
  currentUserProfileType: null,
  currentUserIsPaying: null,
  genreOptions: null,
  activeModal: null,
  AMIProcessing: false,
  atmoAdModalOpen: false,
  userMediaModalOpen: false,
  amiOptionsOpen: false,
  amiOptions: [],
  currentChatRoomId: null, // FOR AMI
  amiAudioMuted: false,
  amiSoundType: "fx",
  amiPosts: [],
  simulatedSpeechSentToAMI: false,
  simulatedAMISpeech: null,
  amiTestingOpen: false,
  sharePostModalOpen: false,
  sharePostModalPost: {},
  addSongsModalOpen: false,
  addSongsModalList: []
});

export default new Vuex.Store({
  modules: {
    player: playerModule,
    ops: opsModule,
    events: eventsModule,
    address: addressModule,
    contests: contestsModule,
    products: productsModule,
    accomplishments: accomplishmentsModule,
    notifications: notificationsModule,
    userFriendships: userFriendshipsModule,
    userRelationships: userRelationshipsModule,
    profiles: profilesModule,
    posts: postsModule,
    liveListeningRooms: liveListeningRoomsModule,
    chatRooms: chatRoomsModule,
    musicProfiles: musicProfilesModule,
    userActivities: userActivitiesModule,
    playlists: playlistsModule,
    charts: chartsModule,
    atmoRadioStations: atmoRadioStationsModule,
    atmoQueues: atmoQueuesModule,
    libraries: librariesModule,
    videoPlayer: videoPlayerModule,
  },
  state: defaultState(),
  mutations: {
    // Authentication
    authenticate() {
      if (localStorage.token) {
        setDefaultAxiosHeaders(localStorage.token);
        this.commit("parseToken", localStorage.token);
        this.commit("getAndSetUserAttributes");
      } else {
        return false;
      }
    },
    getAndSetUserAttributes(state) {
      state.authenticationPromise = Axios.get(`/api/v1/users/${state.currentUserId}`)
        .then(response => {
          const user = response.data;
          Vue.set(state, 'user', new User(user));
          this.dispatch("libraries/getLibrary");
          this.dispatch("getGenreOptions");
          this.dispatch('atmoQueues/getAtmoQueue');
          this.dispatch("userFriendships/fetchUserFriendships", user.id);
          this.dispatch("chatRooms/getGroupChatRooms", user.id);
          this.dispatch("liveListeningRooms/getFriendLiveListeningChatRooms", user.id);
          this.dispatch("liveListeningRooms/getCurrentUserLiveListeningChatRoom", user.id);
          this.dispatch("liveListeningRooms/getLiveListeningChatRoomSongs", user.id);
          this.commit("setAuthenticated", true);
          this.commit("getUserAmiProfileAttributes");
          this.dispatch("musicProfiles/buildUserMusicProfile");
          this.dispatch("notifications/fetchUserNotifications", user.id);
          this.dispatch("notifications/connectToNotifications");
          this.dispatch('liveListeningRooms/connectToChatRoomUserRequests', user.live_listening_chat_room.id);
          this.dispatch('userActivities/connectToBroadcastPosts', {
            roomId: 1,
            params: {
              date_range: 'all',
              limit: 30,
              show_current_user_posts: true
            }
          });
          // this.dispatch('userActivities/fetchUserActivityTypes', user.id);
        })
        .catch(error => {
          console.error(error);
        });
    },
    // eslint-disable-next-line no-unused-vars
    resetStore() {
      const defState = {
        ...defaultState(),
        player: playerModule.state,
        ops: opsModule.state,
        events: eventsModule.state,
        address: addressModule.state,
        contests: contestsModule.state,
        products: productsModule.state,
        accomplishments: accomplishmentsModule.state,
        notifications: notificationsModule.state,
        userFriendships: userFriendshipsModule.state,
        userRelationships: userRelationshipsModule.state,
        profiles: profilesModule.state,
        posts: postsModule.state,
        liveListeningRooms: liveListeningRoomsModule.state,
        chatRooms: chatRoomsModule.state,
        musicProfiles: musicProfilesModule.state,
        userActivities: userActivitiesModule.state,
        playlists: playlistsModule.state,
        charts: chartsModule.state,
        atmoRadioStations: atmoRadioStationsModule.state,
        atmoQueues: atmoQueuesModule.state,
        libraries: librariesModule.state,
        videoPlayer: videoPlayerModule.state,
      };
      this.replaceState(defState);
    },
    parseToken(state, token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      const id = JSON.parse(window.atob(base64)).sub;
      const email = JSON.parse(window.atob(base64)).email;
      const name = JSON.parse(window.atob(base64)).name;
      const profile_id = JSON.parse(window.atob(base64)).profile_id;
      const profile_type = JSON.parse(window.atob(base64)).profile_type;
      const is_paying = JSON.parse(window.atob(base64)).is_paying;
      this.commit("setCurrentUserId", id);
      this.commit("setCurrentUserEmail", email);
      this.commit("setCurrentUserName", name);
      this.commit("setCurrentUserProfileId", profile_id);
      this.commit("setCurrentUserProfileType", profile_type);
      this.commit("setCurrentUserPlanType", is_paying);
      this.commit("setToken", token);
    },
    setAuthenticated(state, newValue) {
      state.authenticated = newValue;
    },
    setGenreOptions(state, genreOptions) {
      state.genreOptions = genreOptions;
    },
    showModal(state, name) {
      state.activeModal = name;
    },
    hideModal(state) {
      state.activeModal = null;
    },
    openSharePostModal(state) {
      state.sharePostModalOpen = true;
    },
    openAddSongsModal(state) {
      state.addSongsModalOpen = true;
    },
    setToken(state, token) {
      state.token = token;
    },
    setCurrentUserId(state, id) {
      state.currentUserId = Number(id);
    },
    setCurrentUserName(state, name) {
      state.currentUserName = name;
    },
    setCurrentUserEmail(state, email) {
      state.currentUserEmail = email;
    },
    setCurrentUserProfileId(state, profileId) {
      state.currentUserProfileId = profileId;
    },
    setCurrentUserProfileType(state, profileType) {
      state.currentUserProfileType = profileType;
    },
    setCurrentUserPlanType(state, planType) {
      state.currentUserIsPaying = planType;
    },
    showAMIProcessing(state, duration = 1000) {
      state.AMIProcessing = true;
      setTimeout(() => {
        state.AMIProcessing = false;
      }, duration);
    },
    openAtmoAdModal(state) {
      state.atmoAdModalOpen = true;
    },
    closeatmoAdModalOpen(state) {
      state.atmoAdModalOpen = false;
    },
    getUserAmiProfileAttributes(state) {
      Axios.get(`/api/v1/user_ami_profiles/${state.currentUserId}`)
        .then(response => {
          state.amiAudioMuted = response.data.ami_audio_muted;
          state.amiAudioSoundType = response.data.ami_sound_type;
        })
        .catch(error => {
          console.error(error);
        });
    },
    setAmiAudioState(state, muted) {
      Axios({
        method: "patch",
        url: `/api/v1/user_ami_profiles/${state.currentUserId}`,
        data: {
          ami_audio_muted: muted
        }
      })
        .then(response => {
          state.amiAudioMuted = response.data.ami_audio_muted;
        })
        .catch(error => {
          console.error(error);
        });
    },
    setAmiSoundType(state, type) {
      Axios({
        method: "patch",
        url: `/api/v1/user_ami_profiles/${state.currentUserId}`,
        data: {
          ami_sound_type: type
        }
      })
        .then(response => {
          state.amiSoundType = response.data.ami_sound_type;
        })
        .catch(error => {
          console.error(error);
        });
    },
    openAmiOptions(state, options) {
      state.amiOptions = options;
      state.amiOptionsOpen = true;
    },
    closeAmiOptions(state) {
      state.amiOptionsOpen = false;
    },
    openAmiTestingModal(state) {
      state.amiTestingOpen = true;
    },
    closeAmiTestingModal(state) {
      state.amiTestingOpen = false;
    },
    sendSimulatedAMISpeech(state, speech) {
      console.log("Firing in store");
      state.simulatedSpeechSentToAMI = true;
      state.simulatedAMISpeech = speech;
      console.log(state.simulatedSpeechSentToAMI, state.simulatedAMISpeech);
    },
    clearSimulatedAMISpeech(state) {
      state.simulatedSpeechSentToAMI = false;
      state.simulatedAMISpeech = null;
    },
    setChatRoomId(state, roomId) {
      state.currentChatRoomId = roomId;
    },
    setAmiPosts(state, posts) {
      state.amiPosts = posts;
    },
    setSharePostModalPost(state, value) {
      state.sharePostModalPost = value;
    },
    setAddSongsModalList(state, data) {
      state.addSongsModalList = data;
    }
  },
  actions: {
    setToken(context, token) {
      context.commit("setToken", token);
    },

    async getGenreOptions({ state, commit }) {
      if (!state.genreOptions) {
        const response = await Axios.get("/api/v1/genres");
        commit("setGenreOptions", response.data);
      }
    },
    stubAction() {
      const promise = Promise.reject(new Error());

      promise.catch(() => {
        console.error("Action not implemented yet");
      });

      return promise;
    },
  }
});
